import { Controller } from "@hotwired/stimulus";
import Swal from "sweetalert2";

// Connects to data-controller="products--size-guide"
export default class extends Controller {
  static values = {
    fileUrl: String,
  };

  open(event) {
    event.preventDefault();

    Swal.fire({
      imageUrl: this.fileUrlValue,
    });
  }
}

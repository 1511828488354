// Entry point for the build script in your package.json
import "@hotwired/turbo-rails";
import "@rails/actiontext";
import "chartkick/chart.js";
import "trix";

import ahoy from "ahoy.js";
ahoy.configure({ cookies: false });

import "./controllers";

import { Controller } from "@hotwired/stimulus";
import TomSelect from "tom-select";
import I18n from "../../i18n";

// Connects to data-controller="stores--products"
export default class extends Controller {
  static targets = ["availableProductsSelect"];
  static values = {
    productsUrl: String,
  };

  connect() {
    this.availableProductsSelect = new TomSelect(
      this.availableProductsSelectTarget,
      {
        valueField: "id",
        labelField: "searchable_name",
        searchField: ["name", "reference"],
        load: (query, callback) => {
          if (!query.length) return callback();

          fetch(`${this.productsUrlValue}&query=${encodeURIComponent(query)}`)
            .then((response) => response.json())
            .then((products) => {
              callback(products);
            })
            .catch(() => {
              callback();
            });
        },
        placeholder: I18n.t("admin.stores.show.search_products"),
        maxOptions: 10,
        persist: false,
        render: {
          no_results: (data, escape) => {
            return `<div class="no-results">${I18n.t(
              "admin.stores.show.no_matching_products"
            )}</div>`;
          },
        },
        onChange: (value) => {
          this.availableProductsSelect.lock();
        },
      }
    );
  }

  disconnect() {
    this.availableProductsSelect.destroy();
  }
}

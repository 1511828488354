import { Application } from "@hotwired/stimulus";
import Notification from "@stimulus-components/notification";
import Sortable from "@stimulus-components/sortable";

const application = Application.start();
application.register("notification", Notification);
application.register("sortable", Sortable);

// Configure Stimulus development experience
application.debug = false;
window.Stimulus = application;

export { application };

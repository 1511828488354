import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="addresses"
export default class extends Controller {
  static targets = [
    "shippingName",
    "shippingFirstName",
    "shippingLastName",
    "shippingCompany",
    "shippingVatNumber",
    "shippingStreet",
    "shippingStreetInformation",
    "shippingZipcode",
    "shippingCity",
    "shippingState",
    "shippingCountry",
    "shippingPhone",
    "billingName",
    "billingFirstName",
    "billingLastName",
    "billingCompany",
    "billingVatNumber",
    "billingStreet",
    "billingStreetInformation",
    "billingZipcode",
    "billingCity",
    "billingState",
    "billingCountry",
    "billingPhone",
  ];

  useShippingAddress(event) {
    event.preventDefault();

    this.billingNameTarget.value = this.shippingNameTarget.value;
    this.billingFirstNameTarget.value = this.shippingFirstNameTarget.value;
    this.billingLastNameTarget.value = this.shippingLastNameTarget.value;
    this.billingCompanyTarget.value = this.shippingCompanyTarget.value;
    this.billingStreetTarget.value = this.shippingStreetTarget.value;
    this.billingStreetInformationTarget.value =
      this.shippingStreetInformationTarget.value;
    this.billingZipcodeTarget.value = this.shippingZipcodeTarget.value;
    this.billingCityTarget.value = this.shippingCityTarget.value;
    this.billingStateTarget.value = this.shippingStateTarget.value;
    this.billingCountryTarget.value = this.shippingCountryTarget.value;
    this.billingPhoneTarget.value = this.shippingPhoneTarget.value;

    if (this.hasShippingVatNumberTarget) {
      this.billingVatNumberTarget.value = this.shippingVatNumberTarget.value;
    }
  }
}

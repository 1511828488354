import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="action-button"
export default class extends Controller {
  static values = {
    disableWith: String,
  };

  clicked(_event) {
    if (this.disableWithValue) {
      this.element.disabled = true;
      this.element.innerHTML = this.disableWithValue;
    }

    this.element.form.submit();
  }
}

import { Controller } from "@hotwired/stimulus";
import Tagify from "@yaireo/tagify";

// Connects to data-controller="products--tags"
export default class extends Controller {
  connect() {
    // const initialValue = this.element.value
    //   .split(", ") // Split the string by commas
    //   .map((tag) => tag.trim()) // Trim whitespace around each tag
    //   .filter((tag) => tag.length > 0); // Remove empty tags (if any)

    // console.log("this.element.value", this.element.value);
    // console.log("initialValue", initialValue);

    this.tagify = new Tagify(this.element, {
      originalInputValueFormat: (valuesArr) =>
        valuesArr.map((item) => item.value).join(", "),
    });

    // Add the preprocessed tags to Tagify
    // this.tagify.addTags(initialValue);
  }
}

/* eslint-disable */
// File generated by i18n-js
import { I18n } from "i18n-js";
const i18n = new I18n({
  "en": {
    "actions": "Actions",
    "activerecord": {
      "attributes": {
        "address": {
          "address_type": "Type",
          "address_types": {
            "billing": "Facturation",
            "shipping": "Livraison"
          },
          "addressable_id": "Identifiant",
          "addressable_type": "Type",
          "city": "Ville",
          "company": "Entreprise",
          "country": "Pays",
          "default": "Adresse par défaut",
          "email": "Email",
          "first_name": "Prénom",
          "id": "ID",
          "last_name": "Nom",
          "latitude": "Latitude",
          "longitude": "Longitude",
          "name": "Nom de l'adresse",
          "phone": "Téléphone",
          "state": "Region",
          "street": "Rue",
          "street_information": "Informations",
          "vat_number": "Numéro de TVA",
          "zipcode": "Code postal"
        },
        "cart": {
          "id": "ID",
          "promo_code": "Code promo",
          "store": "Boutique",
          "user": "Client"
        },
        "cart_item": {
          "cart_id": "Panier",
          "customization_keys": {
            "back": "Dos",
            "chest": "Poitrine",
            "heart": "Cœur",
            "left_sleeve": "Manche gauche",
            "right_sleeve": "Manche droite"
          },
          "customizations": "Personnalisations",
          "id": "ID",
          "product_variant_id": "Produit",
          "quantity": "Quantité"
        },
        "commission": {
          "delivery_price": "Frais de livraison",
          "ends_on": "Fin",
          "id": "ID",
          "sofidial_part": "Part Sofidial",
          "starts_on": "Début",
          "store_part": "Part Club",
          "stripe_fee": "Frais Stripe",
          "total": "Total",
          "vat": "TVA"
        },
        "favorite": {
          "id": "ID",
          "product_variant_id": "Produit",
          "store_id": "Boutique",
          "user_id": "Client"
        },
        "order": {
          "delivery_method": "Mode de livraison",
          "delivery_methods": {
            "delivery": "Livraison",
            "pickup": "Retrait chez %{store}"
          },
          "delivery_price": "Prix de livraison",
          "discount": "Remise",
          "id": "ID",
          "multiple_deliveries": "Expédition multiple",
          "paid_at": "Payée le",
          "promo_code": "Code promo",
          "reference": "Référence",
          "state": "État",
          "states": {
            "awaiting_payment": "En attente de paiement",
            "cancelled": "Annulée",
            "delivered": "Livrée",
            "paid": "Payée",
            "partially_shipped": "Partiellement expédiée",
            "pending": "En attente",
            "shipped": "Expédiée"
          },
          "store": "Boutique",
          "subtotal": "Sous-total",
          "total": "Total",
          "user": "Client"
        },
        "order_item": {
          "customization_keys": {
            "back": "Dos",
            "chest": "Poitrine",
            "heart": "Cœur",
            "left_sleeve": "Manche gauche",
            "right_sleeve": "Manche droite"
          },
          "customizations": "Personnalisations",
          "id": "ID",
          "order_id": "Commande",
          "product_variant_id": "Produit",
          "quantity": "Quantité"
        },
        "parcel": {
          "carrier": "Transporteur",
          "carriers": {
            "dpd": "DPD"
          },
          "delivered_on": "Date de livraison",
          "id": "ID",
          "order_id": "Commande",
          "order_items": "Articles",
          "order_items_count": "Nombre d'articles",
          "state": "État",
          "states": {
            "delivered": "Livré",
            "pending": "En préparation",
            "returned": "Retourné",
            "shipped": "Expédié"
          },
          "tracking_number": "Numéro de suivi",
          "weight": "Poids"
        },
        "product": {
          "brand": "Marque",
          "description": "Description",
          "enabled": "Activé",
          "id": "ID",
          "images": "Images",
          "mode": "Mode",
          "modes": {
            "children": "Enfant",
            "men": "Homme",
            "mixed": "Mixte",
            "women": "Femme"
          },
          "name": "Nom",
          "product_category": "Catégorie",
          "product_category_id": "Catégorie",
          "reference": "Référence",
          "sizes_guide": "Guide des tailles",
          "store": "Boutique",
          "store_id": "Boutique",
          "supplier": "Fournisseur",
          "supplier_price": "Prix fournisseur",
          "suppliers": {
            "imbretex": "Imbretex",
            "other": "Autre"
          },
          "tag_list": "Tags",
          "vat_rate": "Taux de TVA",
          "vat_rates": {
            "vat_20": "20%"
          },
          "weight": "Poids (en grammes)"
        },
        "product_category": {
          "group": "Groupe",
          "groups": {
            "accessories": "Accessoires",
            "clothing": "Vêtements"
          },
          "id": "ID",
          "name": "Nom",
          "products_count": "Nombre de produits"
        },
        "product_color": {
          "group": "Groupe de couleurs",
          "groups": {
            "ash": "Cendre",
            "black": "Noir",
            "blue": "Bleu",
            "brown": "Marron",
            "burgundy": "Bordeaux",
            "dark_grey": "Gris foncé",
            "green": "Vert",
            "grey": "Gris",
            "heather": "Herbe",
            "khaki": "Kaki",
            "misc": "Divers",
            "natural": "Naturelle",
            "navy": "Navy",
            "orange": "Orange",
            "pink": "Rose",
            "purple": "Mauve",
            "red": "Rouge",
            "sand": "Sable",
            "white": "Blanc",
            "yellow": "Jaune"
          },
          "hex": "Code hexadecimal",
          "id": "ID",
          "name": "Nom",
          "products_count": "Nombre de produits"
        },
        "product_variant": {
          "description": "Description",
          "enabled": "Activée",
          "front": "Mis en avant",
          "id": "ID",
          "images": "Images",
          "name": "Nom",
          "price": "Prix",
          "primary_image": "Image principale",
          "product_color_ids": "Couleurs",
          "product_id": "Produit",
          "reference": "Référence",
          "should_create_other_product_variants": "Créer les autres variantes de même couleur du produit",
          "should_update_other_product_variants": "Mettre à jour les autres variantes du produit",
          "size": "Taille",
          "sofidial_price": "Prix SOFIDIAL",
          "store_id": "Boutique",
          "supplier_price": "Prix fournisseur",
          "weight": "Poids"
        },
        "promo_code": {
          "code_type": "Type",
          "code_types": {
            "fixed": "Fixe",
            "percentage": "Pourcentage"
          },
          "enabled": "Activé",
          "expires_at": "Expire le",
          "id": "ID",
          "name": "Nom",
          "store": "Boutique",
          "store_id": "Boutique",
          "value": "Valeur"
        },
        "store": {
          "accent_color": "Couleur d'accentuation",
          "banner": "Bannière",
          "banner_message": "Message de la bannière",
          "billing_address": "Adresse facturation",
          "billing_email": "Adresse email de facturation",
          "description": "Description",
          "domain": "Domaine",
          "enabled": "Activé",
          "id": "ID",
          "name": "Nom",
          "order_prefix": "Préfixe de commande",
          "shipping_address": "Adresse de livraison",
          "subdomain": "Sous-domaine"
        },
        "supplier_order": {
          "external_id": "ID externe",
          "id": "ID",
          "metadata": "Métadonnées",
          "order_id": "Commande",
          "state": "État",
          "states": {
            "created": "Créée",
            "failed": "Échouée"
          },
          "supplier": "Fournisseur",
          "suppliers": {
            "imbretex": "Imbretex",
            "other": "Autre"
          }
        },
        "user": {
          "current_password": "Mot de passe actuel",
          "email": "Email",
          "first_name": "Prénom",
          "full_name": "Nom complet",
          "gender": "Genre",
          "genders": {
            "female": "Femme",
            "male": "Homme",
            "other": "Autre"
          },
          "id": "ID",
          "last_name": "Nom de famille",
          "password": "Mot de passe",
          "password_confirmation": "Confirmation du mot de passe",
          "user_type": "Type",
          "user_types": {
            "admin": "Admin",
            "customer": "Client",
            "store_admin": "Admin magasin"
          }
        }
      },
      "errors": {
        "messages": {
          "record_invalid": "Validation failed: %{errors}",
          "restrict_dependent_destroy": {
            "has_many": "Cannot delete record because dependent %{record} exist",
            "has_one": "Cannot delete record because a dependent %{record} exists"
          }
        },
        "models": {
          "address": {
            "company_or_name_missing": "Vous devez indiquer vos nom et prénom ou votre entreprise."
          },
          "product_variant": {
            "attributes": {
              "price": {
                "price_lower_than_sofidial_price": "doit être supérieur ou égal au prix SOFIDIAL"
              },
              "sofidial_price": {
                "sofidial_price_lower_than_supplier_price": "doit être supérieur ou égal au prix fournisseur"
              }
            }
          }
        }
      },
      "models": {
        "address": "Adresse",
        "billing_address": "Adresse de facturation",
        "cart": "Panier",
        "cart_item": "Article",
        "commission": "Commission",
        "favorite": "Favori",
        "order": "Commande",
        "order_item": "Article",
        "parcel": "Colis",
        "product": "Produit",
        "product_category": "Catégorie de produit",
        "product_color": "Couleur de produit",
        "product_variant": "Variante de produit",
        "promo_code": "Code promo",
        "shipping_address": "Adresse de livraison",
        "store": "Boutique",
        "supplier_order": "Commande fournisseur",
        "user": "Utilisateur"
      }
    },
    "add": "Ajouter",
    "addresses": {
      "address": {
        "type": "Type : %{type}"
      },
      "edit": {
        "edit_address": "Modifier l'adresse %{address}",
        "save": "Sauvegarder",
        "title": "Modifier une adresse"
      },
      "form": {
        "address_default_help": "Si activé, cette adresse sera utilisée comme adresse par défaut pour %{type}."
      },
      "index": {
        "my_addresses": "Mes adresses",
        "title": "Adresses"
      }
    },
    "admin": {
      "commissions": {
        "commission": {
          "discount": "Remise : %{amount}",
          "sofidial_part": "Part Sofidial : %{amount}",
          "store_part": "Part Club : %{amount}",
          "total": "Total : %{amount}"
        },
        "index": {
          "title": "Commissions"
        }
      },
      "controllers": {
        "addresses": {
          "create": {
            "notice": "Adresse créée."
          },
          "destroy": {
            "notice": "Adresse supprimée."
          },
          "update": {
            "notice": "Adresse mise à jour."
          }
        }
      },
      "dashboard": {
        "index": {
          "last_month_orders": "Commandes du mois dernier par jour",
          "title": "Statistiques"
        }
      },
      "order_items": {
        "order_item": {
          "color": "Couleur",
          "quantity": "Quantité",
          "size": "Taille"
        }
      },
      "orders": {
        "index": {
          "title": "Commandes"
        },
        "order": {
          "delivery": "Expédition : %{type}",
          "paid_at": "Payée le : %{date}",
          "total": "Total : %{amount}"
        },
        "show": {
          "actions": "Actions disponibles",
          "add_parcel": "Ajouter un colis",
          "available_for_pickup": "Disponible pour le retrait",
          "billing_address": "Adresse de facturation",
          "cancel": "Annuler",
          "cancel_confirmation": "Annuler la commande %{order} ?",
          "charges": "Paiements",
          "customer": "Client",
          "delivery_to_store": "📦 Livraison à la boutique %{store}",
          "invoices": "Factures",
          "items": "Articles",
          "multiple_deliveries": "Expédition multiple",
          "no_customer": "Aucun client",
          "order": "Commande",
          "parcels": "Colis",
          "ship_confirmation": "Marquer la commande %{order} comme disponible pour le retrait ?",
          "shipping_address": "Adresse de livraison",
          "summary": "Résumé",
          "supplier_orders": "Commandes fournisseur",
          "title": "Commande %{order}"
        },
        "supplier_order": {
          "state": "État : %{state}"
        }
      },
      "parcels": {
        "form": {
          "add": "Ajouter"
        },
        "index": {
          "title": "Colis"
        }
      },
      "product_categories": {
        "edit": {
          "title": "Modification de la catégorie %{product_category}"
        },
        "form": {
          "information": "Informations générales"
        },
        "index": {
          "title": "Catégories"
        },
        "new": {
          "title": "Nouvelle catégorie"
        },
        "product_category": {
          "created_at": "Créée le : %{date}",
          "delete_confirmation": "Supprimer la catégorie %{product_category} ?",
          "group": "Groupe : %{group}"
        },
        "show": {
          "title": "Catégorie %{product_category}"
        }
      },
      "product_colors": {
        "edit": {
          "title": "Modification de la couleur %{product_color}"
        },
        "form": {
          "current_hex_value_html": "Valeur actuelle : <code>%{hex}</code>",
          "information": "Informations générales"
        },
        "index": {
          "title": "Couleurs"
        },
        "new": {
          "title": "Nouvelle couleur"
        },
        "product_color": {
          "created_at": "Créée le : %{date}",
          "delete_confirmation": "Supprimer la couleur %{product_color} ?",
          "group": "Groupe : %{group}",
          "hex": "Code hexadécimal : %{hex}"
        },
        "show": {
          "title": "Couleur %{product_color}"
        }
      },
      "product_variants": {
        "edit": {
          "title": "Modification de la variante %{product_variant}"
        },
        "form": {
          "base_variant": "Il s'agit de la variante de base importée depuis le fournisseur et n'étant pas liée à une boutique.",
          "batch_create": "Création en lot",
          "batch_update": "Mise à jour en lot",
          "create_other_product_variants_help": "Si activé, le variant sera dupliqué pour toutes les tailles du produit.",
          "images": "Images",
          "information": "Informations générales",
          "no_images": "Aucune image",
          "no_primary_image": "Aucune image principale",
          "pricing": "Tarification",
          "product_variant_enabled_help": "Les variantes de produits inactives ne sont pas achetables et restent cachés.",
          "store": "Boutique",
          "store_variant": "Il s'agit d'une variante liée à la boutique %{store}.",
          "supplier": "Informations fournisseur (%{supplier})",
          "update_other_product_variants_help": "Si activé, le nom et le prix de cette variante seront appliqués aux autres variantes de ce produit. Les images des variantes de taille différente, mais avec les mêmes couleurs, seront également mises à jour."
        },
        "image": {
          "delete": "Supprimer",
          "delete_confirmation": "Supprimer cette image ?"
        },
        "index": {
          "title": "Variantes de %{product}"
        },
        "new": {
          "title": "Nouvelle variante pour %{product}"
        },
        "product_variant": {
          "delete_confirmation": "Supprimer la variante %{product_variant} ?",
          "global": "Commun à toutes les boutiques",
          "price": "Prix : %{price}",
          "size": "Taille : %{size}",
          "specific_to_store": "Spécifique à %{store}"
        },
        "show": {
          "title": "Variante %{product_variant}"
        },
        "update": {
          "title": "Modification de la variante %{product_variant}"
        }
      },
      "products": {
        "edit": {
          "title": "Modification du produit %{product}"
        },
        "form": {
          "base_product": "Il s'agit du produit de base importé depuis le fournisseur et n'étant pas liée à une boutique.",
          "images": "Images",
          "information": "Informations générales",
          "no_images": "Aucune image",
          "no_sizes_guide": "Aucun guide de tailles disponible",
          "open_sizes_guide": "Voir le guide",
          "pricing": "Tarification",
          "product_enabled_help": "Les produits inactifs ne sont pas achetables et restent cachés.",
          "store": "Boutique",
          "store_product": "Il s'agit d'un produit lié à la boutique %{store}.",
          "supplier": "Fournisseur",
          "tags_placeholder": "Séparez vos tags par un espace"
        },
        "image": {
          "delete": "Supprimer",
          "delete_confirmation": "Supprimer cette image ?"
        },
        "index": {
          "title": "Produits"
        },
        "new": {
          "title": "Nouveau produit"
        },
        "product": {
          "created_at": "Créé le : %{date}",
          "delete_confirmation": "Supprimer le produit %{product} ?",
          "global": "Commun à toutes les boutiques",
          "reference": "Référence : %{reference}",
          "specific_to_store": "Spécifique à %{store}",
          "supplier": "Fournisseur : %{supplier}"
        },
        "show": {
          "add_product_variant": "Ajouter un variant",
          "disable_all": "Désactiver tout",
          "disable_color_confirmation": "Désactiver toutes les variantes de la couleur %{color} ?",
          "duplicate_for_store": "Dupliquer pour %{store}",
          "duplicate_for_store_confirmation": "Dupliquer le produit et tous ses variants pour la boutique %{store} ?",
          "enable_all": "Activer tout",
          "enable_color_confirmation": "Activer toutes les variantes de la couleur %{color} ?",
          "title": "Produit %{product}",
          "variants": "Variantes"
        },
        "update": {
          "title": "Modification du produit %{product}"
        }
      },
      "promo_codes": {
        "edit": {
          "title": "Modification du code promo %{promo_code}"
        },
        "form": {
          "information": "Informations générales",
          "promo_code_enabled_help": "Les codes promo inactifs ne sont pas utilisables sur les boutiques."
        },
        "index": {
          "title": "Codes promo"
        },
        "new": {
          "title": "Nouveau code promo"
        },
        "promo_code": {
          "code_type": "Type : %{type}",
          "created_at": "Créé le : %{date}",
          "delete_confirmation": "Supprimer le code promo %{promo_code} ?",
          "value": "Valeur : %{value}"
        },
        "show": {
          "title": "Code promo %{promo_code}"
        }
      },
      "search": {
        "index": {
          "search_results": "Résultats de recherche pour %{query}",
          "title": "Résultats de recherche pour %{query}"
        }
      },
      "stores": {
        "edit": {
          "title": "Modification de la boutique %{store}"
        },
        "form": {
          "billing_address": "Adresse facturation",
          "dns_setup_documentation_html": "Créez un enregistrement <code>CNAME</code> pointant vers <code>%{target}<c/ode> dans la zone DNS de votre domaine.",
          "information": "Informations générales",
          "payment_and_billing": "Paiement et facturation",
          "shipping_address": "Adresse de livraison",
          "store_enabled_help": "Les boutiques inactives restent cachées.",
          "urls": "URLs",
          "use_shipping_address": "Identique livraison"
        },
        "index": {
          "title": "Boutiques"
        },
        "new": {
          "title": "Nouvelle boutique"
        },
        "product": {
          "delete_confirmation": "Désactiver le produit %{product} de la boutique ?",
          "deleting": "Désactivation en cours...",
          "product_since": "Depuis le %{date}",
          "store_product": "Spécifique à %{store}",
          "variants": {
            "one": "1 variante",
            "other": "%{count} variantes",
            "zero": "Aucune variante"
          }
        },
        "show": {
          "add_admin": "Ajouter",
          "add_product": "Ajouter",
          "no_matching_products": "Aucun produit ne correspond à votre recherche.",
          "products": "Produits",
          "search_products": "Rechercher un produit par nom ou référence",
          "select_a_new_admin": "Sélectionnez un nouvel administrateur",
          "select_a_new_product": "Sélectionnez un nouveau produit",
          "sort": "Trier",
          "store_admins": "Administrateurs",
          "store_products": "Produits actifs",
          "title": "Boutique %{store}"
        },
        "sort_products": {
          "title": "Trier les produits"
        },
        "user": {
          "admin_since": "Depuis le %{date}",
          "delete_confirmation": "Supprimer l'administrateur %{user} de la boutique ?"
        }
      },
      "users": {
        "edit": {
          "title": "Modification de l'utilisateur %{user}"
        },
        "form": {
          "authentication": "Authentification",
          "information": "Informations générales"
        },
        "index": {
          "title": "Utilisateurs"
        },
        "new": {
          "title": "Nouvel utilisateur"
        },
        "show": {
          "title": "Utilisateur %{user}"
        },
        "user": {
          "created_at": "Créé(e) le : %{date}",
          "delete_confirmation": "Supprimer l'utilisateur %{user} ?",
          "email": "Email : %{email}",
          "user_type": "Type : %{type}"
        }
      }
    },
    "back": "Retour",
    "cancel": "Annuler",
    "cancelled_by_admin": "Annulée par l'administrateur",
    "cart_items": {
      "cart_item": {
        "color": "Couleur",
        "delete_confirmation": "Supprimer %{product_variant} de votre panier?",
        "incoming_stock_date": "Nous recevrons %{stock} articles le %{date}.",
        "no_incoming_stock_date": "Nous n'avons pas de date de réapprovisionnement pour le moment.",
        "no_stock_warning": "Nous n'avons plus cet article en stock.",
        "quantity": "Quantité",
        "size": "Taille",
        "stock_warning": "Nous n'avons que %{current_stock} articles en stock."
      }
    },
    "carts": {
      "show": {
        "delivery": "Livraison",
        "discount": "Remise",
        "my_cart": "Mon panier",
        "next": "Suivant",
        "ok": "OK",
        "processing": "Traitement...",
        "title": "Panier",
        "to_be_defined": "à définir",
        "total": "Total",
        "total_items": {
          "one": "1 article",
          "other": "%{count} articles",
          "zero": "Aucun article"
        }
      }
    },
    "controllers": {
      "addresses": {
        "update": {
          "notice": "Adresse mise à jour."
        }
      },
      "admin": {
        "orders": {
          "cancel": {
            "notice": "Commande annulée."
          },
          "ship": {
            "notice": "Commande marquée comme disponible pour le retrait."
          }
        },
        "parcels": {
          "create": {
            "notice": "Colis créé."
          },
          "update": {
            "notice": "Colis mis à jour."
          }
        },
        "product_categories": {
          "create": {
            "alert": "Echec de création de la catégorie.",
            "notice": "Catégorie créée."
          },
          "destroy": {
            "notice": "Catégorie supprimée."
          },
          "update": {
            "alert": "Echec de mise à jourt de la catégorie.",
            "notice": "Catégorie mise à jour."
          }
        },
        "product_colors": {
          "create": {
            "alert": "Echec de création de la couleur.",
            "notice": "Couleur créée."
          },
          "destroy": {
            "notice": "Couleur supprimée."
          },
          "update": {
            "alert": "Echec de mise à jourt de la couleur.",
            "notice": "Couleur mise à jour."
          }
        },
        "product_variants": {
          "create": {
            "alert": "Echec de création de la variante.",
            "notice": "Variante créée."
          },
          "destroy": {
            "notice": "Variante supprimée."
          },
          "destroy_image": {
            "notice": "Image supprimée."
          },
          "front": {
            "notice": "Variante mise en avant."
          },
          "update": {
            "alert": "Echec de mise à jour de la variante.",
            "notice": "Variante mise à jour."
          }
        },
        "products": {
          "create": {
            "alert": "Echec de création du produit.",
            "notice": "Produit créé."
          },
          "destroy": {
            "notice": "Produit supprimé."
          },
          "destroy_image": {
            "notice": "Image supprimée."
          },
          "disable_color": {
            "notice": "Toutes les variantes de couleur %{color} ont été désactivées pour cette boutique."
          },
          "duplicate": {
            "notice": "Produit dupliqué."
          },
          "enable_color": {
            "notice": "Toutes les variantes de couleur %{color} ont été activées pour cette boutique."
          },
          "update": {
            "alert": "Echec de mise à jour du produit.",
            "notice": "Produit mis à jour."
          }
        },
        "promo_codes": {
          "create": {
            "alert": "Echec de création du code promo.",
            "notice": "Code promo créé."
          },
          "destroy": {
            "notice": "Code promo supprimé."
          },
          "update": {
            "notice": "Code promo mis à jour."
          }
        },
        "stores": {
          "add_admin": {
            "notice": "Administrateur boutique ajouté."
          },
          "add_product": {
            "notice": "Produit en cours d'ajouté sur la boutique."
          },
          "create": {
            "alert": "Echec de création de la boutique.",
            "notice": "Boutique créée."
          },
          "current": {
            "notice": "Boutique sélectionnée."
          },
          "destroy": {
            "notice": "Boutique supprimée."
          },
          "remove_admin": {
            "notice": "Administrateur boutique supprimé. 👋"
          },
          "remove_product": {
            "notice": "Produit en cours de désactivation sur la boutique."
          },
          "update": {
            "alert": "Echec de mise à jour de la boutique.",
            "notice": "Boutique mise à jour."
          }
        },
        "users": {
          "create": {
            "alert": "Echec de création de l'utilisateur.",
            "notice": "Utilisateur créé."
          },
          "destroy": {
            "notice": "Utilisateur supprimé."
          },
          "update": {
            "alert": "Echec de mise à jourt de l'utilisateur.",
            "notice": "Utilisateur mis à jour."
          }
        }
      },
      "application": {
        "user_not_authorized": "Vous n'êtes pas autorisé à effectuer cette action."
      },
      "cart_items": {
        "create": {
          "notice": "%{product} ajouté au panier."
        },
        "destroy": {
          "notice": "%{product} supprimé."
        },
        "update": {
          "notice": "%{product} mis à jour."
        }
      },
      "carts": {
        "checkout": {
          "notice": "Commande créée."
        },
        "update": {
          "notice": "Panier mis à jour."
        }
      },
      "order_items": {
        "create": {
          "notice": "%{product} ajouté au panier."
        }
      },
      "orders": {
        "checkout": {
          "notice": "Commande créée, choisissez un mode de livraison."
        },
        "payment": {
          "alert": "Veuillez remplir vos informations de livraison et de facturation avant de passer à la paiement."
        },
        "update": {
          "notice": "Commande mise à jour."
        }
      },
      "store_admin": {
        "orders": {
          "cancel": {
            "notice": "Commande annulée."
          }
        },
        "product_variants": {
          "create": {
            "alert": "Echec de création de la variante.",
            "notice": "Variante créée."
          },
          "destroy": {
            "notice": "Variante supprimée."
          },
          "destroy_image": {
            "notice": "Image supprimée."
          },
          "front": {
            "notice": "Variante mise en avant."
          },
          "update": {
            "alert": "Echec de mise à jour de la variante.",
            "notice": "Variante mise à jour."
          }
        },
        "products": {
          "create": {
            "alert": "Echec de création du produit.",
            "notice": "Produit créé."
          },
          "destroy": {
            "notice": "Produit supprimé."
          },
          "destroy_image": {
            "notice": "Image supprimée."
          },
          "disable_color": {
            "notice": "Toutes les variantes de couleur %{color} ont été désactivées pour cette boutique."
          },
          "duplicate": {
            "notice": "Produit dupliqué."
          },
          "enable_color": {
            "notice": "Toutes les variantes de couleur %{color} ont été activées pour cette boutique."
          },
          "update": {
            "alert": "Echec de mise à jour du produit.",
            "notice": "Produit mis à jour."
          }
        },
        "promo_codes": {
          "create": {
            "alert": "Echec de création du code promo.",
            "notice": "Code promo créé."
          },
          "destroy": {
            "notice": "Code promo supprimé."
          },
          "update": {
            "notice": "Code promo mis à jour."
          }
        },
        "stores": {
          "add_product": {
            "notice": "Produit en cours d'ajouté sur la boutique."
          },
          "current": {
            "notice": "Boutique sélectionnée."
          },
          "remove_product": {
            "notice": "Produit en cours de désactivation sur la boutique."
          },
          "update": {
            "alert": "Echec de mise à jour de la boutique.",
            "notice": "Boutique mise à jour."
          }
        }
      }
    },
    "date": {
      "abbr_day_names": [
        "Sun",
        "Mon",
        "Tue",
        "Wed",
        "Thu",
        "Fri",
        "Sat"
      ],
      "abbr_month_names": [
        null,
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
      ],
      "day_names": [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
      ],
      "formats": {
        "default": "%Y-%m-%d",
        "long": "%B %d, %Y",
        "short": "%b %d"
      },
      "month_names": [
        null,
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ],
      "order": [
        "year",
        "month",
        "day"
      ]
    },
    "datetime": {
      "distance_in_words": {
        "about_x_hours": {
          "one": "about %{count} hour",
          "other": "about %{count} hours"
        },
        "about_x_months": {
          "one": "about %{count} month",
          "other": "about %{count} months"
        },
        "about_x_years": {
          "one": "about %{count} year",
          "other": "about %{count} years"
        },
        "almost_x_years": {
          "one": "almost %{count} year",
          "other": "almost %{count} years"
        },
        "half_a_minute": "half a minute",
        "less_than_x_minutes": {
          "one": "less than a minute",
          "other": "less than %{count} minutes"
        },
        "less_than_x_seconds": {
          "one": "less than %{count} second",
          "other": "less than %{count} seconds"
        },
        "over_x_years": {
          "one": "over %{count} year",
          "other": "over %{count} years"
        },
        "x_days": {
          "one": "%{count} day",
          "other": "%{count} days"
        },
        "x_minutes": {
          "one": "%{count} minute",
          "other": "%{count} minutes"
        },
        "x_months": {
          "one": "%{count} month",
          "other": "%{count} months"
        },
        "x_seconds": {
          "one": "%{count} second",
          "other": "%{count} seconds"
        },
        "x_years": {
          "one": "%{count} year",
          "other": "%{count} years"
        }
      },
      "prompts": {
        "day": "Day",
        "hour": "Hour",
        "minute": "Minute",
        "month": "Month",
        "second": "Seconds",
        "year": "Year"
      }
    },
    "delete": "Supprimer",
    "devise": {
      "confirmations": {
        "confirmed": "Your email address has been successfully confirmed.",
        "new": {
          "or_continue_with": "ou continuer avec",
          "resend_confirmation_info": "Envoyer les instructions",
          "title": "Compte non confirmé ?"
        },
        "send_instructions": "You will receive an email with instructions for how to confirm your email address in a few minutes.",
        "send_paranoid_instructions": "If your email address exists in our database, you will receive an email with instructions for how to confirm your email address in a few minutes."
      },
      "failure": {
        "already_authenticated": "You are already signed in.",
        "inactive": "Your account is not activated yet.",
        "invalid": "Invalid %{authentication_keys} or password.",
        "last_attempt": "You have one more attempt before your account is locked.",
        "locked": "Your account is locked.",
        "not_found_in_database": "Invalid %{authentication_keys} or password.",
        "timeout": "Your session expired. Please sign in again to continue.",
        "unauthenticated": "You need to sign in or sign up before continuing.",
        "unconfirmed": "You have to confirm your email address before continuing."
      },
      "mailer": {
        "confirmation_instructions": {
          "action": "Confirmer mon email",
          "greeting": "Bienvenue %{recipient} !",
          "instruction": "Vous pouvez confirmer votre email grâce au lien ci-dessous :",
          "subject": "Confirmation instructions"
        },
        "email_changed": {
          "greeting": "Bonjour %{recipient} !",
          "message": "Nous vous contactons pour vous informer que votre email a été changé en %{email}.",
          "message_unconfirmed": "Nous vous contactons pour vous informer que votre email est en train d'être changé par %{email}.",
          "subject": "Email Changed"
        },
        "password_change": {
          "greeting": "Bonjour %{recipient} !",
          "message": "Nous vous contactons pour vous notifier que votre mot de passe a été modifié.",
          "subject": "Password Changed"
        },
        "reset_password_instructions": {
          "action": "Changer mon mot de passe",
          "greeting": "Bonjour %{recipient} !",
          "instruction": "Quelqu'un a demandé un lien pour changer votre mot de passe, le voici :",
          "instruction_2": "Si vous n'avez pas émis cette demande, merci d'ignorer cet email.",
          "instruction_3": "Votre mot de passe ne changera pas tant que vous n'aurez pas cliqué sur ce lien et renseigné un nouveau mot de passe.",
          "subject": "Reset password instructions"
        },
        "unlock_instructions": {
          "action": "Débloquer mon compte",
          "greeting": "Bonjour %{recipient} !",
          "instruction": "Suivez ce lien pour débloquer votre compte :",
          "message": "Votre compte a été bloqué suite à un nombre d'essais de connexions manquées trop important.",
          "subject": "Unlock instructions"
        }
      },
      "omniauth_callbacks": {
        "failure": "Could not authenticate you from %{kind} because \"%{reason}\".",
        "success": "Successfully authenticated from %{kind} account."
      },
      "passwords": {
        "edit": {
          "change_my_password": "Modifier mon mot de passe",
          "confirm_new_password": "Confirmer le nouveau mot de passe",
          "minimum_password_length": "Minimum de %{count} caractères",
          "new_password": "Nouveau mot de passe",
          "or_continue_with": "ou continuer avec",
          "title": "Modifier mon mot de passe"
        },
        "new": {
          "or_continue_with": "ou continuer avec",
          "send_password_reset_info": "Envoyer les instructions",
          "title": "Mot de passe oublié ?"
        },
        "no_token": "You can't access this page without coming from a password reset email. If you do come from a password reset email, please make sure you used the full URL provided.",
        "send_instructions": "You will receive an email with instructions on how to reset your password in a few minutes.",
        "send_paranoid_instructions": "If your email address exists in our database, you will receive a password recovery link at your email address in a few minutes.",
        "updated": "Your password has been changed successfully. You are now signed in.",
        "updated_not_active": "Your password has been changed successfully."
      },
      "registrations": {
        "destroyed": "Bye! Your account has been successfully cancelled. We hope to see you again soon.",
        "new": {
          "already_a_member": "Déjà inscrit ?",
          "minimum_password_length": "Minimum de %{count} caractères",
          "or_continue_with": "ou continuer avec",
          "sign_up": "Inscription",
          "signin_to_your_account": "Connectez vous.",
          "title": "Inscription"
        },
        "signed_up": "Welcome! You have signed up successfully.",
        "signed_up_but_inactive": "You have signed up successfully. However, we could not sign you in because your account is not yet activated.",
        "signed_up_but_locked": "You have signed up successfully. However, we could not sign you in because your account is locked.",
        "signed_up_but_unconfirmed": "A message with a confirmation link has been sent to your email address. Please follow the link to activate your account.",
        "update_needs_confirmation": "You updated your account successfully, but we need to verify your new email address. Please check your email and follow the confirmation link to confirm your new email address.",
        "updated": "Your account has been updated successfully.",
        "updated_but_not_signed_in": "Your account has been updated successfully, but since your password was changed, you need to sign in again."
      },
      "sessions": {
        "already_signed_out": "Signed out successfully.",
        "new": {
          "not_a_member": "Pas encore inscrit ?",
          "or": "ou",
          "or_continue_with": "ou continuer avec",
          "remember_me": "Rester connecté",
          "sign_in": "Connexion",
          "signup": "Inscrivez-vous",
          "title": "Connexion"
        },
        "signed_in": "Signed in successfully.",
        "signed_out": "Signed out successfully."
      },
      "shared": {
        "links": {
          "didnt_receive_confirm_info": "Informations de confirmation non reçues ?",
          "didnt_receive_unlock_info": "Informations de déverrouillage non reçues ?",
          "forgot_password": "Mot de passe oublié ?",
          "signin": "Connexion",
          "signup": "Inscription"
        }
      },
      "unlocks": {
        "new": {
          "or_continue_with": "ou continuer avec",
          "resend_unlock_instructions": "Envoyer les instructions",
          "title": "Compte verrouillé ?"
        },
        "send_instructions": "You will receive an email with instructions for how to unlock your account in a few minutes.",
        "send_paranoid_instructions": "If your account exists, you will receive an email with instructions for how to unlock it in a few minutes.",
        "unlocked": "Your account has been unlocked successfully. Please sign in to continue."
      }
    },
    "display_period": "Période d'affichage",
    "edit": "Modifier",
    "errors": {
      "connection_refused": "Oops! Failed to connect to the Web Console middleware.\nPlease make sure a rails development server is running.\n",
      "format": "%{attribute} %{message}",
      "messages": {
        "accepted": "must be accepted",
        "already_confirmed": "was already confirmed, please try signing in",
        "aspect_ratio_is_not": "must have an aspect ratio of %{aspect_ratio}",
        "aspect_ratio_not_landscape": "must be a landscape image",
        "aspect_ratio_not_portrait": "must be a portrait image",
        "aspect_ratio_not_square": "must be a square image",
        "blank": "can't be blank",
        "confirmation": "doesn't match %{attribute}",
        "confirmation_period_expired": "needs to be confirmed within %{period}, please request a new one",
        "content_type_invalid": "has an invalid content type",
        "dimension_height_equal_to": "height must be equal to %{length} pixel",
        "dimension_height_greater_than_or_equal_to": "height must be greater than or equal to %{length} pixel",
        "dimension_height_inclusion": "height is not included between %{min} and %{max} pixel",
        "dimension_height_less_than_or_equal_to": "height must be less than or equal to %{length} pixel",
        "dimension_max_inclusion": "must be less than or equal to %{width} x %{height} pixel",
        "dimension_min_inclusion": "must be greater than or equal to %{width} x %{height} pixel",
        "dimension_width_equal_to": "width must be equal to %{length} pixel",
        "dimension_width_greater_than_or_equal_to": "width must be greater than or equal to %{length} pixel",
        "dimension_width_inclusion": "width is not included between %{min} and %{max} pixel",
        "dimension_width_less_than_or_equal_to": "width must be less than or equal to %{length} pixel",
        "empty": "can't be empty",
        "equal_to": "must be equal to %{count}",
        "even": "must be even",
        "exclusion": "is reserved",
        "expired": "has expired, please request a new one",
        "file_size_not_between": "file size must be between %{min_size} and %{max_size} (current size is %{file_size})",
        "file_size_not_greater_than": "file size must be greater than %{min_size} (current size is %{file_size})",
        "file_size_not_greater_than_or_equal_to": "file size must be greater than or equal to %{min_size} (current size is %{file_size})",
        "file_size_not_less_than": "file size must be less than %{max_size} (current size is %{file_size})",
        "file_size_not_less_than_or_equal_to": "file size must be less than or equal to %{max_size} (current size is %{file_size})",
        "greater_than": "must be greater than %{count}",
        "greater_than_or_equal_to": "must be greater than or equal to %{count}",
        "image_metadata_missing": "is not a valid image",
        "image_not_processable": "is not a valid image",
        "in": "must be in %{count}",
        "inclusion": "is not included in the list",
        "invalid": "is invalid",
        "less_than": "must be less than %{count}",
        "less_than_or_equal_to": "must be less than or equal to %{count}",
        "limit_out_of_range": "total number is out of range",
        "model_invalid": "Validation failed: %{errors}",
        "not_a_number": "is not a number",
        "not_an_integer": "must be an integer",
        "not_found": "not found",
        "not_locked": "was not locked",
        "not_saved": {
          "one": "1 error prohibited this %{resource} from being saved:",
          "other": "%{count} errors prohibited this %{resource} from being saved:"
        },
        "odd": "must be odd",
        "other_than": "must be other than %{count}",
        "password_too_long": "is too long",
        "present": "must be blank",
        "required": "must exist",
        "spoofed_content_type": "has a content type that is not what it is declared through its content, filename and extension",
        "taken": "has already been taken",
        "too_long": {
          "one": "is too long (maximum is %{count} character)",
          "other": "is too long (maximum is %{count} characters)"
        },
        "too_short": {
          "one": "is too short (minimum is %{count} character)",
          "other": "is too short (minimum is %{count} characters)"
        },
        "total_file_size_not_between": "total file size must be between %{min_size} and %{max_size} (current size is %{total_file_size})",
        "total_file_size_not_greater_than": "total file size must be greater than %{min_size} (current size is %{total_file_size})",
        "total_file_size_not_greater_than_or_equal_to": "total file size must be greater than or equal to %{min_size} (current size is %{total_file_size})",
        "total_file_size_not_less_than": "total file size must be less than %{max_size} (current size is %{total_file_size})",
        "total_file_size_not_less_than_or_equal_to": "total file size must be less than or equal to %{max_size} (current size is %{total_file_size})",
        "wrong_length": {
          "one": "is the wrong length (should be %{count} character)",
          "other": "is the wrong length (should be %{count} characters)"
        }
      },
      "template": {
        "body": "There were problems with the following fields:",
        "header": {
          "one": "%{count} error prohibited this %{model} from being saved",
          "other": "%{count} errors prohibited this %{model} from being saved"
        }
      },
      "unacceptable_request": "A supported version is expected in the Accept header.\n",
      "unavailable_session": "Session %{id} is no longer available in memory.\n\nIf you happen to run on a multi-process server (like Unicorn or Puma) the process\nthis request hit doesn't store %{id} in memory. Consider turning the number of\nprocesses/workers to one (1) or using a different server in development.\n"
    },
    "export": "Exporter",
    "favorites": {
      "favorite": {
        "add_to_cart": "Ajouter au panier"
      },
      "index": {
        "title": "Mes favoris"
      }
    },
    "filter": "Filtrer :",
    "filters": {
      "periods": {
        "all_time": "Depuis toujours",
        "this_month": "Ce mois",
        "this_week": "Cette semaine",
        "this_year": "Cette année",
        "today": "Aujourd'hui"
      }
    },
    "flash": {
      "actions": {
        "create": {
          "notice": "%{resource_name} was successfully created."
        },
        "destroy": {
          "alert": "%{resource_name} could not be destroyed.",
          "notice": "%{resource_name} was successfully destroyed."
        },
        "update": {
          "notice": "%{resource_name} was successfully updated."
        }
      }
    },
    "hello": "Hello world",
    "helpers": {
      "select": {
        "prompt": "Please select"
      },
      "submit": {
        "address": {
          "create": "Créer l'%{model}",
          "update": "Modifier l'%{model}"
        },
        "create": "Create %{model}",
        "product": {
          "create": "Créer le %{model}",
          "update": "Modifier le %{model}"
        },
        "product_category": {
          "create": "Créer la %{model}",
          "update": "Modifier la %{model}"
        },
        "product_color": {
          "create": "Créer la %{model}",
          "update": "Modifier la %{model}"
        },
        "product_variant": {
          "create": "Créer la %{model}",
          "update": "Modifier la %{model}"
        },
        "promo_code": null,
        "store": {
          "create": "Créer la %{model}",
          "update": "Modifier la %{model}"
        },
        "submit": "Save %{model}",
        "update": "Update %{model}",
        "user": {
          "create": "Créer l'%{model}",
          "update": "Modifier l'%{model}"
        }
      }
    },
    "home": "Accueil",
    "i18n": {
      "plural": {
        "keys": [
          "one",
          "other"
        ]
      },
      "transliterate": {
        "rule": {
          "À": "A",
          "Â": "A",
          "Æ": "Ae",
          "Ç": "C",
          "È": "E",
          "É": "E",
          "Ê": "E",
          "Ë": "E",
          "Î": "I",
          "Ï": "I",
          "Ô": "O",
          "Ù": "U",
          "Û": "U",
          "Ü": "U",
          "à": "a",
          "â": "a",
          "æ": "ae",
          "ç": "c",
          "è": "e",
          "é": "e",
          "ê": "e",
          "ë": "e",
          "î": "i",
          "ï": "i",
          "ô": "o",
          "ù": "u",
          "û": "u",
          "ü": "u",
          "ÿ": "y",
          "Œ": "Oe",
          "œ": "oe",
          "Ÿ": "Y"
        }
      }
    },
    "layouts": {
      "admin": {
        "all_stores": "Toutes les boutiques",
        "commissions": "Commissions",
        "configuration": "Configuration",
        "dashboard": "Tableau de bord",
        "jobs": "Tâches",
        "monitoring": "Surveillance",
        "orders": "Commandes",
        "product_categories": "Catégories produits",
        "product_colors": "Couleurs produits",
        "products": "Produits",
        "promo_codes": "Codes promo",
        "statistics": "Statistiques",
        "stores": "Boutiques",
        "users": "Utilisateurs",
        "your_profile": "Votre profil"
      },
      "application": {
        "legal": "Mentions légales",
        "privacy": "Politique de confidentialité",
        "terms": "Conditions générales de vente"
      },
      "store_admin": {
        "all_my_stores": "Toutes mes boutiques",
        "configuration": "Configuration",
        "dashboard": "Tableau de bord",
        "orders": "Commandes",
        "products": "Produits",
        "promo_codes": "Codes promo",
        "statistics": "Statistiques",
        "stores": "Boutiques",
        "your_profile": "Votre profil"
      }
    },
    "missing": "Manquant(e)",
    "no_data": "Aucune donnée à afficher 🥲",
    "number": {
      "currency": {
        "format": {
          "delimiter": ",",
          "format": "%u%n",
          "negative_format": "-%u%n",
          "precision": 2,
          "separator": ".",
          "significant": false,
          "strip_insignificant_zeros": false,
          "unit": "$"
        }
      },
      "format": {
        "delimiter": ",",
        "precision": 3,
        "round_mode": "default",
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false
      },
      "human": {
        "decimal_units": {
          "format": "%n %u",
          "units": {
            "billion": "Billion",
            "million": "Million",
            "quadrillion": "Quadrillion",
            "thousand": "Thousand",
            "trillion": "Trillion",
            "unit": ""
          }
        },
        "format": {
          "delimiter": "",
          "precision": 3,
          "significant": true,
          "strip_insignificant_zeros": true
        },
        "storage_units": {
          "format": "%n %u",
          "units": {
            "byte": {
              "one": "Byte",
              "other": "Bytes"
            },
            "eb": "EB",
            "gb": "GB",
            "kb": "KB",
            "mb": "MB",
            "pb": "PB",
            "tb": "TB",
            "zb": "ZB"
          }
        }
      },
      "nth": {},
      "percentage": {
        "format": {
          "delimiter": "",
          "format": "%n%"
        }
      },
      "precision": {
        "format": {
          "delimiter": ""
        }
      }
    },
    "open": "Ouvrir",
    "order_items": {
      "order_item": {
        "color": "Couleur",
        "quantity": "Quantité",
        "size": "Taille"
      }
    },
    "orders": {
      "delivery": {
        "account": "Compte",
        "billing_address": "Adresse de facturation",
        "billing_addresses": "Utiliser une adresse de facturation",
        "free": "Gratuit",
        "maximum_incoming_stock_date": "Seconde expédition possible après le %{date}.",
        "multiple_deliveries_help": "Les articles en stock seront expédiés immédiatement et le reste plus tard. Si vous optez pour la livraison, vous paierez deux fois les frais de port.",
        "my_order": "Ma commande",
        "next": "Suivant",
        "no_incoming_stock_date": "Nous ne savons pas encore quand nous recevrons les articles manquants.",
        "processing": "Traitement...",
        "shipping_address": "Adresse de livraison",
        "shipping_addresses": "Utiliser une adresse de livraison",
        "stock_warning": "Nous n'avons pas l'intégralité de vos articles en stock. Vous pouvez opter pour une expédition des articles en stock suivi d'une seconde livraison avec le reste des articles lors que nous les aurons.",
        "title": "Livraison",
        "use_shipping_address": "Identique livraison"
      },
      "failure": {
        "error_occured": "Une erreur est survenue pendant la validation de votre commande dans la boutique %{store}.",
        "failure": "Commande non validée. 👎",
        "my_order": "Ma commande",
        "retry": "Réessayer",
        "title": "Commande annulée"
      },
      "index": {
        "my_orders": "Mes commandes",
        "title": "Commandes"
      },
      "invoice": {
        "delivery": "Livraison",
        "discount": "Remise",
        "item": {
          "name": "Article",
          "quantity": "Quantité",
          "total": "Total",
          "unit_price": "Prix unitaire"
        },
        "order_reference": "Référence de la commande",
        "paid_at": "Date de paiement",
        "subtotal": "Sous-total",
        "taxless_subtotal": "Sous-total HT",
        "title": "Facture %{reference}",
        "total": "Total",
        "vat": "TVA"
      },
      "order": {
        "delivery": "Expédition : %{type}",
        "paid_at": "Payée le : %{date}",
        "total": "Total : %{amount}"
      },
      "order_information": {
        "delivery": "Livraison",
        "discount": "Remise",
        "free": "Gratuite",
        "to_be_defined": "à définir",
        "total": "Total",
        "total_items": {
          "one": "1 article",
          "other": "%{count} articles",
          "zero": "Aucun article"
        }
      },
      "payment": {
        "next": "Suivant",
        "title": "Paiement"
      },
      "show": {
        "invoice": "Facture %{reference}",
        "my_orders": "Mes commandes",
        "state": "État de la commande :",
        "title": "Commande %{reference}"
      },
      "success": {
        "my_order": "Ma commande",
        "next": "Suivant",
        "success": "Commande validée ! 👍",
        "thank_you_for_your_order": "Merci pour votre commande dans la boutique %{store}.",
        "title": "Commande validée"
      }
    },
    "pay": {
      "all_rights_reserved": "All rights reserved.",
      "back": "Go back",
      "cancelled": {
        "description": "This payment was cancelled.",
        "header": "Payment Cancelled"
      },
      "errors": {
        "action_required": "This payment attempt failed because additional action is required before it can be completed.",
        "braintree": {
          "authorization": "Either the data you submitted is malformed and does not match the API or the API key you used may not be authorized to perform this action."
        },
        "email_required": "Email is required to create a customer",
        "invalid_payment": "This payment attempt failed because of an invalid payment method.",
        "no_processor": "No payment processor selected. Make sure to set the %{class_name}'s `processor` attribute to either 'stripe' or 'braintree'."
      },
      "invoice": {
        "date": "Date",
        "number": "Invoice Number",
        "payment_method": "Payment method"
      },
      "line_items": {
        "amount": "Amount",
        "amount_discount": "%{name} (%{amount} off)",
        "description": "Description",
        "discount": "Discount",
        "percent_discount": "%{name} (%{percent}% off)",
        "quantity": "Quantity",
        "subtotal": "Subtotal",
        "tax": "Tax",
        "total": "Total",
        "unit_price": "Unit Price"
      },
      "receipt": {
        "amount_paid": "Amount paid",
        "date": "Date",
        "number": "Receipt Number",
        "paid": "Paid",
        "payment_method": "Payment Method",
        "refunded": "Refunded",
        "refunded_on": "Refunded on %{date}"
      },
      "refund": "Refund",
      "requires_action": {
        "button": "Pay %{amount}",
        "card": "Credit or debit card",
        "description": "Extra confirmation is needed to process your payment. Please confirm your payment by filling out your payment details below.",
        "full_name": "Full name",
        "header": "Confirm your %{amount} payment",
        "name_missing": "Please provide your name.",
        "success": "The payment was successful."
      },
      "successful": {
        "description": "This payment was successfully confirmed.",
        "header": "Payment Successful"
      },
      "user_mailer": {
        "payment_action_required": {
          "subject": "[%{application}] Confirm your payment"
        },
        "payment_failed": {
          "subject": "[%{application}] Action Required – Your payment failed"
        },
        "receipt": {
          "subject": "[%{application}] Payment receipt"
        },
        "refund": {
          "subject": "[%{application}] Payment refunded"
        },
        "subscription_renewing": {
          "subject": "[%{application}] Your upcoming subscription renewal"
        },
        "subscription_trial_ended": {
          "subject": "[%{application}] Your trial has ended"
        },
        "subscription_trial_will_end": {
          "subject": "[%{application}] Your trial is ending soon"
        }
      }
    },
    "products": {
      "product": {
        "add_to_cart": "Ajouter"
      },
      "search": {
        "accessories": "Accessoires",
        "all_products": "Tous nos produits",
        "clothing": "Vêtements",
        "filter_color": "Couleurs",
        "filter_mode": "Mode",
        "filter_products": "Catégorie de produits",
        "filter_size": "Tailles",
        "pagy_product": {
          "one": "produit",
          "other": "produits",
          "zero": "produit"
        },
        "search_products": "Rechercher des produits",
        "title": "Résultats de recherche"
      },
      "show": {
        "add_to_cart": "Ajouter au panier",
        "back_customization": "Dos (%{price})",
        "chest_customization": "Poitrine (%{price})",
        "colors": "Couleurs",
        "customize": "Personnaliser",
        "customize_your_product": "Personnalisez votre produit",
        "description": "Description",
        "heart_customization": "Coeur (%{price})",
        "incoming_stock_date": "Réapprovisionnement à partir du %{date}.",
        "left_sleeve_customization": "Manche gauche (%{price})",
        "out_of_stock": "Nous n'avons plus cet article en stock.",
        "quantity": "Quantité",
        "right_sleeve_customization": "Manche droite (%{price})",
        "similar_products": "Produits similaires",
        "sizes": "Tailles",
        "sizes_guide": "Guide des tailles",
        "title": "Produit %{product}"
      }
    },
    "profiles": {
      "edit": {
        "account": "Compte",
        "leave_blank_if_you_dont_want_to_change_it": "(laissez vide si vous ne souhaitez pas le changer)",
        "minimum_password_length": "Minimum de %{count} caractères",
        "my_information": "Mon compte",
        "save": "Sauvegarder",
        "title": "Mon compte",
        "waiting_confirmation": "En attente de confirmation pour %{email}"
      }
    },
    "search": {
      "result": {
        "created_at": "Créé(e) il y a %{time}",
        "updated_at": "Mis(e) à jour il y a %{time}"
      }
    },
    "search_placeholder": "Rechercher...",
    "shared": {
      "account_navigation": {
        "addresses": "Adresses",
        "orders": "Commandes",
        "profile": "Profile"
      },
      "form_errors": {
        "save_error": "Erreur lors de la sauvegarde"
      },
      "funnel_steps": {
        "cart": "Panier",
        "delivery": "Livraison",
        "summary": "Récapitulatif",
        "summary_short": "Récap"
      }
    },
    "show": "Afficher",
    "store_admin": {
      "dashboard": {
        "index": {
          "last_month_orders": "Commandes du mois dernier par jour",
          "title": "Statistiques"
        }
      },
      "orders": {
        "index": {
          "title": "Commandes"
        },
        "order": {
          "delivery": "Expédition : %{type}",
          "paid_at": "Payée le : %{date}",
          "total": "Total : %{amount}"
        },
        "show": {
          "actions": "Actions disponibles",
          "billing_address": "Adresse de facturation",
          "cancel": "Annuler",
          "cancel_confirmation": "Annuler la commande %{order} ?",
          "customer": "Client",
          "delivery_to_store": "📦 Livraison à la boutique %{store}",
          "invoices": "Factures",
          "items": "Articles",
          "multiple_deliveries": "Expédition multiple",
          "no_customer": "Aucun client",
          "order": "Commande",
          "parcels": "Colis",
          "shipping_address": "Adresse de livraison",
          "summary": "Résumé",
          "title": "Commande %{order}"
        }
      },
      "product_variants": {
        "edit": {
          "title": "Modification de la variante %{product_variant}"
        },
        "form": {
          "base_variant": "Il s'agit de la variante de base importée depuis le fournisseur et n'étant pas liée à une boutique.",
          "batch_create": "Création en lot",
          "batch_update": "Mise à jour en lot",
          "create_other_product_variants_help": "Si activé, le variant sera dupliqué pour toutes les tailles du produit.",
          "images": "Images",
          "information": "Informations générales",
          "no_images": "Aucune image",
          "no_primary_image": "Aucune image principale",
          "pricing": "Tarification",
          "product_variant_enabled_help": "Les variantes de produits inactives ne sont pas achetables et restent cachés.",
          "store": "Boutique",
          "store_variant": "Il s'agit d'une variante liée à la boutique %{store}.",
          "supplier": "Informations fournisseur (%{supplier})",
          "update_other_product_variants_help": "Si activé, le nom et le prix de cette variante seront appliqués aux autres variantes de ce produit. Les images des variantes de taille différente, mais avec les mêmes couleurs, seront également mises à jour."
        },
        "image": {
          "delete": "Supprimer",
          "delete_confirmation": "Supprimer cette image ?"
        },
        "index": {
          "title": "Variantes de %{product}"
        },
        "new": {
          "title": "Nouvelle variante pour %{product}"
        },
        "product_variant": {
          "delete_confirmation": "Supprimer la variante %{product_variant} ?",
          "global": "Commun à toutes les boutiques",
          "price": "Prix : %{price}",
          "size": "Taille : %{size}",
          "specific_to_store": "Spécifique à %{store}"
        },
        "show": {
          "title": "Variante %{product_variant}"
        },
        "update": {
          "title": "Modification de la variante %{product_variant}"
        }
      },
      "products": {
        "edit": {
          "title": "Modification du produit %{product}"
        },
        "form": {
          "base_product": "Il s'agit du produit de base importé depuis le fournisseur et n'étant pas liée à une boutique.",
          "images": "Images",
          "information": "Informations générales",
          "no_images": "Aucune image",
          "no_sizes_guide": "Aucun guide de tailles disponible",
          "open_sizes_guide": "Voir le guide",
          "pricing": "Tarification",
          "product_enabled_help": "Les produits inactifs ne sont pas achetables et restent cachés.",
          "store": "Boutique",
          "store_product": "Il s'agit d'un produit lié à la boutique %{store}.",
          "supplier": "Fournisseur",
          "tags_placeholder": "Séparez vos tags par un espace"
        },
        "image": {
          "delete": "Supprimer",
          "delete_confirmation": "Supprimer cette image ?"
        },
        "index": {
          "title": "Mes produits"
        },
        "new": {
          "title": "Nouveau produit"
        },
        "product": {
          "created_at": "Créé le : %{date}",
          "delete_confirmation": "Supprimer le produit %{product} ?",
          "global": "Commun à toutes les boutiques",
          "reference": "Référence : %{reference}",
          "specific_to_store": "Spécifique à %{store}",
          "supplier": "Fournisseur : %{supplier}"
        },
        "show": {
          "add_product_variant": "Ajouter un variant",
          "disable_all": "Désactiver tout",
          "disable_color_confirmation": "Désactiver toutes les variantes de la couleur %{color} ?",
          "duplicate_for_store": "Dupliquer pour %{store}",
          "duplicate_for_store_confirmation": "Dupliquer le produit et tous ses variants pour la boutique %{store} ?",
          "enable_all": "Activer tout",
          "enable_color_confirmation": "Activer toutes les variantes de la couleur %{color} ?",
          "title": "Produit %{product}",
          "variants": "Variantes"
        },
        "update": {
          "title": "Modification du produit %{product}"
        }
      },
      "promo_codes": {
        "edit": {
          "title": "Modification du code promo %{promo_code}"
        },
        "form": {
          "information": "Informations générales",
          "promo_code_enabled_help": "Les codes promo inactifs ne sont pas utilisables sur les boutiques."
        },
        "index": {
          "title": "Codes promo"
        },
        "new": {
          "title": "Nouveau code promo"
        },
        "promo_code": {
          "code_type": "Type : %{type}",
          "created_at": "Créé le : %{date}",
          "delete_confirmation": "Supprimer le code promo %{promo_code} ?",
          "value": "Valeur : %{value}"
        },
        "show": {
          "title": "Code promo %{promo_code}"
        }
      },
      "search": {
        "index": {
          "search_results": "Résultats de recherche pour %{query}",
          "title": "Résultats de recherche pour %{query}"
        }
      },
      "stores": {
        "edit": {
          "title": "Modification de la boutique %{store}"
        },
        "form": {
          "billing_address": "Adresse facturation",
          "dns_setup_documentation_html": "Créez un enregistrement <code>CNAME</code> pointant vers <code>%{target}<c/ode> dans la zone DNS de votre domaine.",
          "information": "Informations générales",
          "payment_and_billing": "Paiement et facturation",
          "shipping_address": "Adresse de livraison",
          "store_enabled_help": "Les boutiques inactives restent cachées.",
          "urls": "URLs",
          "use_shipping_address": "Identique livraison"
        },
        "index": {
          "title": "Mes boutiques"
        },
        "product": {
          "delete_confirmation": "Désactiver le produit %{product} de la boutique ?",
          "deleting": "Désactivation en cours...",
          "product_since": "Depuis le %{date}",
          "store_product": "Spécifique à %{store}",
          "variants": {
            "one": "1 variante",
            "other": "%{count} variantes",
            "zero": "Aucune variante"
          }
        },
        "show": {
          "add_admin": "Ajouter",
          "add_product": "Ajouter",
          "no_matching_products": "Aucun produit ne correspond à votre recherche.",
          "products": "Produits",
          "search_products": "Rechercher un produit par nom ou référence",
          "select_a_new_admin": "Sélectionnez un nouvel administrateur",
          "select_a_new_product": "Sélectionnez un nouveau produit",
          "sort": "Trier",
          "store_admins": "Administrateurs",
          "store_products": "Produits actifs",
          "title": "Boutique %{store}"
        },
        "sort_products": {
          "title": "Trier les produits"
        },
        "user": {
          "admin_since": "Depuis le %{date}"
        }
      }
    },
    "stores": {
      "show": {
        "accessories": "Accessoires",
        "all_products": "Tous nos produits",
        "clothing": "Vêtements",
        "filter_color": "Couleurs",
        "filter_mode": "Mode",
        "filter_products": "Catégorie de produits",
        "filter_size": "Tailles",
        "pagy_product": {
          "one": "produit",
          "other": "produits",
          "zero": "produit"
        },
        "search_products": "Rechercher des produits",
        "title": "Tous nos produits"
      }
    },
    "support": {
      "array": {
        "last_word_connector": ", and ",
        "two_words_connector": " and ",
        "words_connector": ", "
      }
    },
    "time": {
      "am": "am",
      "formats": {
        "default": "%a, %d %b %Y %H:%M:%S %z",
        "long": "%B %d, %Y %H:%M",
        "short": "%d %b %H:%M",
        "time": "%H:%M"
      },
      "pm": "pm"
    }
  },
  "fr": {
    "actions": "Actions",
    "activerecord": {
      "attributes": {
        "address": {
          "address_type": "Type",
          "address_types": {
            "billing": "Facturation",
            "shipping": "Livraison"
          },
          "addressable_id": "Identifiant",
          "addressable_type": "Type",
          "city": "Ville",
          "company": "Entreprise",
          "country": "Pays",
          "default": "Adresse par défaut",
          "email": "Email",
          "first_name": "Prénom",
          "id": "ID",
          "last_name": "Nom",
          "latitude": "Latitude",
          "longitude": "Longitude",
          "name": "Nom de l'adresse",
          "phone": "Téléphone",
          "state": "Region",
          "street": "Rue",
          "street_information": "Informations",
          "vat_number": "Numéro de TVA",
          "zipcode": "Code postal"
        },
        "cart": {
          "id": "ID",
          "promo_code": "Code promo",
          "store": "Boutique",
          "user": "Client"
        },
        "cart_item": {
          "cart_id": "Panier",
          "customization_keys": {
            "back": "Dos",
            "chest": "Poitrine",
            "heart": "Cœur",
            "left_sleeve": "Manche gauche",
            "right_sleeve": "Manche droite"
          },
          "customizations": "Personnalisations",
          "id": "ID",
          "product_variant_id": "Produit",
          "quantity": "Quantité"
        },
        "commission": {
          "delivery_price": "Frais de livraison",
          "ends_on": "Fin",
          "id": "ID",
          "sofidial_part": "Part Sofidial",
          "starts_on": "Début",
          "store_part": "Part Club",
          "stripe_fee": "Frais Stripe",
          "total": "Total",
          "vat": "TVA"
        },
        "favorite": {
          "id": "ID",
          "product_variant_id": "Produit",
          "store_id": "Boutique",
          "user_id": "Client"
        },
        "order": {
          "delivery_method": "Mode de livraison",
          "delivery_methods": {
            "delivery": "Livraison",
            "pickup": "Retrait chez %{store}"
          },
          "delivery_price": "Prix de livraison",
          "discount": "Remise",
          "id": "ID",
          "multiple_deliveries": "Expédition multiple",
          "paid_at": "Payée le",
          "promo_code": "Code promo",
          "reference": "Référence",
          "state": "État",
          "states": {
            "awaiting_payment": "En attente de paiement",
            "cancelled": "Annulée",
            "delivered": "Livrée",
            "paid": "Payée",
            "partially_shipped": "Partiellement expédiée",
            "pending": "En attente",
            "shipped": "Expédiée"
          },
          "store": "Boutique",
          "subtotal": "Sous-total",
          "total": "Total",
          "user": "Client"
        },
        "order_item": {
          "customization_keys": {
            "back": "Dos",
            "chest": "Poitrine",
            "heart": "Cœur",
            "left_sleeve": "Manche gauche",
            "right_sleeve": "Manche droite"
          },
          "customizations": "Personnalisations",
          "id": "ID",
          "order_id": "Commande",
          "product_variant_id": "Produit",
          "quantity": "Quantité"
        },
        "parcel": {
          "carrier": "Transporteur",
          "carriers": {
            "dpd": "DPD"
          },
          "delivered_on": "Date de livraison",
          "id": "ID",
          "order_id": "Commande",
          "order_items": "Articles",
          "order_items_count": "Nombre d'articles",
          "state": "État",
          "states": {
            "delivered": "Livré",
            "pending": "En préparation",
            "returned": "Retourné",
            "shipped": "Expédié"
          },
          "tracking_number": "Numéro de suivi",
          "weight": "Poids"
        },
        "product": {
          "brand": "Marque",
          "description": "Description",
          "enabled": "Activé",
          "id": "ID",
          "images": "Images",
          "mode": "Mode",
          "modes": {
            "children": "Enfant",
            "men": "Homme",
            "mixed": "Mixte",
            "women": "Femme"
          },
          "name": "Nom",
          "product_category": "Catégorie",
          "product_category_id": "Catégorie",
          "reference": "Référence",
          "sizes_guide": "Guide des tailles",
          "store": "Boutique",
          "store_id": "Boutique",
          "supplier": "Fournisseur",
          "supplier_price": "Prix fournisseur",
          "suppliers": {
            "imbretex": "Imbretex",
            "other": "Autre"
          },
          "tag_list": "Tags",
          "vat_rate": "Taux de TVA",
          "vat_rates": {
            "vat_20": "20%"
          },
          "weight": "Poids (en grammes)"
        },
        "product_category": {
          "group": "Groupe",
          "groups": {
            "accessories": "Accessoires",
            "clothing": "Vêtements"
          },
          "id": "ID",
          "name": "Nom",
          "products_count": "Nombre de produits"
        },
        "product_color": {
          "group": "Groupe de couleurs",
          "groups": {
            "ash": "Cendre",
            "black": "Noir",
            "blue": "Bleu",
            "brown": "Marron",
            "burgundy": "Bordeaux",
            "dark_grey": "Gris foncé",
            "green": "Vert",
            "grey": "Gris",
            "heather": "Herbe",
            "khaki": "Kaki",
            "misc": "Divers",
            "natural": "Naturelle",
            "navy": "Navy",
            "orange": "Orange",
            "pink": "Rose",
            "purple": "Mauve",
            "red": "Rouge",
            "sand": "Sable",
            "white": "Blanc",
            "yellow": "Jaune"
          },
          "hex": "Code hexadecimal",
          "id": "ID",
          "name": "Nom",
          "products_count": "Nombre de produits"
        },
        "product_variant": {
          "description": "Description",
          "enabled": "Activée",
          "front": "Mis en avant",
          "id": "ID",
          "images": "Images",
          "name": "Nom",
          "price": "Prix",
          "primary_image": "Image principale",
          "product_color_ids": "Couleurs",
          "product_id": "Produit",
          "reference": "Référence",
          "should_create_other_product_variants": "Créer les autres variantes de même couleur du produit",
          "should_update_other_product_variants": "Mettre à jour les autres variantes du produit",
          "size": "Taille",
          "sofidial_price": "Prix SOFIDIAL",
          "store_id": "Boutique",
          "supplier_price": "Prix fournisseur",
          "weight": "Poids"
        },
        "promo_code": {
          "code_type": "Type",
          "code_types": {
            "fixed": "Fixe",
            "percentage": "Pourcentage"
          },
          "enabled": "Activé",
          "expires_at": "Expire le",
          "id": "ID",
          "name": "Nom",
          "store": "Boutique",
          "store_id": "Boutique",
          "value": "Valeur"
        },
        "store": {
          "accent_color": "Couleur d'accentuation",
          "banner": "Bannière",
          "banner_message": "Message de la bannière",
          "billing_address": "Adresse facturation",
          "billing_email": "Adresse email de facturation",
          "description": "Description",
          "domain": "Domaine",
          "enabled": "Activé",
          "id": "ID",
          "name": "Nom",
          "order_prefix": "Préfixe de commande",
          "shipping_address": "Adresse de livraison",
          "subdomain": "Sous-domaine"
        },
        "supplier_order": {
          "external_id": "ID externe",
          "id": "ID",
          "metadata": "Métadonnées",
          "order_id": "Commande",
          "state": "État",
          "states": {
            "created": "Créée",
            "failed": "Échouée"
          },
          "supplier": "Fournisseur",
          "suppliers": {
            "imbretex": "Imbretex",
            "other": "Autre"
          }
        },
        "user": {
          "current_password": "Mot de passe actuel",
          "email": "Email",
          "first_name": "Prénom",
          "full_name": "Nom complet",
          "gender": "Genre",
          "genders": {
            "female": "Femme",
            "male": "Homme",
            "other": "Autre"
          },
          "id": "ID",
          "last_name": "Nom de famille",
          "password": "Mot de passe",
          "password_confirmation": "Confirmation du mot de passe",
          "user_type": "Type",
          "user_types": {
            "admin": "Admin",
            "customer": "Client",
            "store_admin": "Admin magasin"
          }
        }
      },
      "errors": {
        "messages": {
          "record_invalid": "La validation a échoué : %{errors}",
          "restrict_dependent_destroy": {
            "has_many": "Vous ne pouvez pas supprimer l'enregistrement parce que les %{record} dépendants existent",
            "has_one": "Vous ne pouvez pas supprimer l'enregistrement car un(e) %{record} dépendant(e) existe"
          }
        },
        "models": {
          "address": {
            "company_or_name_missing": "Vous devez indiquer vos nom et prénom ou votre entreprise."
          },
          "product_variant": {
            "attributes": {
              "price": {
                "price_lower_than_sofidial_price": "doit être supérieur ou égal au prix SOFIDIAL"
              },
              "sofidial_price": {
                "sofidial_price_lower_than_supplier_price": "doit être supérieur ou égal au prix fournisseur"
              }
            }
          }
        }
      },
      "models": {
        "address": "Adresse",
        "billing_address": "Adresse de facturation",
        "cart": "Panier",
        "cart_item": "Article",
        "commission": "Commission",
        "favorite": "Favori",
        "order": "Commande",
        "order_item": "Article",
        "parcel": "Colis",
        "product": "Produit",
        "product_category": "Catégorie de produit",
        "product_color": "Couleur de produit",
        "product_variant": "Variante de produit",
        "promo_code": "Code promo",
        "shipping_address": "Adresse de livraison",
        "store": "Boutique",
        "supplier_order": "Commande fournisseur",
        "user": "Utilisateur"
      }
    },
    "add": "Ajouter",
    "addresses": {
      "address": {
        "type": "Type : %{type}"
      },
      "edit": {
        "edit_address": "Modifier l'adresse %{address}",
        "save": "Sauvegarder",
        "title": "Modifier une adresse"
      },
      "form": {
        "address_default_help": "Si activé, cette adresse sera utilisée comme adresse par défaut pour %{type}."
      },
      "index": {
        "my_addresses": "Mes adresses",
        "title": "Adresses"
      }
    },
    "admin": {
      "commissions": {
        "commission": {
          "discount": "Remise : %{amount}",
          "sofidial_part": "Part Sofidial : %{amount}",
          "store_part": "Part Club : %{amount}",
          "total": "Total : %{amount}"
        },
        "index": {
          "title": "Commissions"
        }
      },
      "controllers": {
        "addresses": {
          "create": {
            "notice": "Adresse créée."
          },
          "destroy": {
            "notice": "Adresse supprimée."
          },
          "update": {
            "notice": "Adresse mise à jour."
          }
        }
      },
      "dashboard": {
        "index": {
          "last_month_orders": "Commandes du mois dernier par jour",
          "title": "Statistiques"
        }
      },
      "order_items": {
        "order_item": {
          "color": "Couleur",
          "quantity": "Quantité",
          "size": "Taille"
        }
      },
      "orders": {
        "index": {
          "title": "Commandes"
        },
        "order": {
          "delivery": "Expédition : %{type}",
          "paid_at": "Payée le : %{date}",
          "total": "Total : %{amount}"
        },
        "show": {
          "actions": "Actions disponibles",
          "add_parcel": "Ajouter un colis",
          "available_for_pickup": "Disponible pour le retrait",
          "billing_address": "Adresse de facturation",
          "cancel": "Annuler",
          "cancel_confirmation": "Annuler la commande %{order} ?",
          "charges": "Paiements",
          "customer": "Client",
          "delivery_to_store": "📦 Livraison à la boutique %{store}",
          "invoices": "Factures",
          "items": "Articles",
          "multiple_deliveries": "Expédition multiple",
          "no_customer": "Aucun client",
          "order": "Commande",
          "parcels": "Colis",
          "ship_confirmation": "Marquer la commande %{order} comme disponible pour le retrait ?",
          "shipping_address": "Adresse de livraison",
          "summary": "Résumé",
          "supplier_orders": "Commandes fournisseur",
          "title": "Commande %{order}"
        },
        "supplier_order": {
          "state": "État : %{state}"
        }
      },
      "parcels": {
        "form": {
          "add": "Ajouter"
        },
        "index": {
          "title": "Colis"
        }
      },
      "product_categories": {
        "edit": {
          "title": "Modification de la catégorie %{product_category}"
        },
        "form": {
          "information": "Informations générales"
        },
        "index": {
          "title": "Catégories"
        },
        "new": {
          "title": "Nouvelle catégorie"
        },
        "product_category": {
          "created_at": "Créée le : %{date}",
          "delete_confirmation": "Supprimer la catégorie %{product_category} ?",
          "group": "Groupe : %{group}"
        },
        "show": {
          "title": "Catégorie %{product_category}"
        }
      },
      "product_colors": {
        "edit": {
          "title": "Modification de la couleur %{product_color}"
        },
        "form": {
          "current_hex_value_html": "Valeur actuelle : <code>%{hex}</code>",
          "information": "Informations générales"
        },
        "index": {
          "title": "Couleurs"
        },
        "new": {
          "title": "Nouvelle couleur"
        },
        "product_color": {
          "created_at": "Créée le : %{date}",
          "delete_confirmation": "Supprimer la couleur %{product_color} ?",
          "group": "Groupe : %{group}",
          "hex": "Code hexadécimal : %{hex}"
        },
        "show": {
          "title": "Couleur %{product_color}"
        }
      },
      "product_variants": {
        "edit": {
          "title": "Modification de la variante %{product_variant}"
        },
        "form": {
          "base_variant": "Il s'agit de la variante de base importée depuis le fournisseur et n'étant pas liée à une boutique.",
          "batch_create": "Création en lot",
          "batch_update": "Mise à jour en lot",
          "create_other_product_variants_help": "Si activé, le variant sera dupliqué pour toutes les tailles du produit.",
          "images": "Images",
          "information": "Informations générales",
          "no_images": "Aucune image",
          "no_primary_image": "Aucune image principale",
          "pricing": "Tarification",
          "product_variant_enabled_help": "Les variantes de produits inactives ne sont pas achetables et restent cachés.",
          "store": "Boutique",
          "store_variant": "Il s'agit d'une variante liée à la boutique %{store}.",
          "supplier": "Informations fournisseur (%{supplier})",
          "update_other_product_variants_help": "Si activé, le nom et le prix de cette variante seront appliqués aux autres variantes de ce produit. Les images des variantes de taille différente, mais avec les mêmes couleurs, seront également mises à jour."
        },
        "image": {
          "delete": "Supprimer",
          "delete_confirmation": "Supprimer cette image ?"
        },
        "index": {
          "title": "Variantes de %{product}"
        },
        "new": {
          "title": "Nouvelle variante pour %{product}"
        },
        "product_variant": {
          "delete_confirmation": "Supprimer la variante %{product_variant} ?",
          "global": "Commun à toutes les boutiques",
          "price": "Prix : %{price}",
          "size": "Taille : %{size}",
          "specific_to_store": "Spécifique à %{store}"
        },
        "show": {
          "title": "Variante %{product_variant}"
        },
        "update": {
          "title": "Modification de la variante %{product_variant}"
        }
      },
      "products": {
        "edit": {
          "title": "Modification du produit %{product}"
        },
        "form": {
          "base_product": "Il s'agit du produit de base importé depuis le fournisseur et n'étant pas liée à une boutique.",
          "images": "Images",
          "information": "Informations générales",
          "no_images": "Aucune image",
          "no_sizes_guide": "Aucun guide de tailles disponible",
          "open_sizes_guide": "Voir le guide",
          "pricing": "Tarification",
          "product_enabled_help": "Les produits inactifs ne sont pas achetables et restent cachés.",
          "store": "Boutique",
          "store_product": "Il s'agit d'un produit lié à la boutique %{store}.",
          "supplier": "Fournisseur",
          "tags_placeholder": "Séparez vos tags par un espace"
        },
        "image": {
          "delete": "Supprimer",
          "delete_confirmation": "Supprimer cette image ?"
        },
        "index": {
          "title": "Produits"
        },
        "new": {
          "title": "Nouveau produit"
        },
        "product": {
          "created_at": "Créé le : %{date}",
          "delete_confirmation": "Supprimer le produit %{product} ?",
          "global": "Commun à toutes les boutiques",
          "reference": "Référence : %{reference}",
          "specific_to_store": "Spécifique à %{store}",
          "supplier": "Fournisseur : %{supplier}"
        },
        "show": {
          "add_product_variant": "Ajouter un variant",
          "disable_all": "Désactiver tout",
          "disable_color_confirmation": "Désactiver toutes les variantes de la couleur %{color} ?",
          "duplicate_for_store": "Dupliquer pour %{store}",
          "duplicate_for_store_confirmation": "Dupliquer le produit et tous ses variants pour la boutique %{store} ?",
          "enable_all": "Activer tout",
          "enable_color_confirmation": "Activer toutes les variantes de la couleur %{color} ?",
          "title": "Produit %{product}",
          "variants": "Variantes"
        },
        "update": {
          "title": "Modification du produit %{product}"
        }
      },
      "promo_codes": {
        "edit": {
          "title": "Modification du code promo %{promo_code}"
        },
        "form": {
          "information": "Informations générales",
          "promo_code_enabled_help": "Les codes promo inactifs ne sont pas utilisables sur les boutiques."
        },
        "index": {
          "title": "Codes promo"
        },
        "new": {
          "title": "Nouveau code promo"
        },
        "promo_code": {
          "code_type": "Type : %{type}",
          "created_at": "Créé le : %{date}",
          "delete_confirmation": "Supprimer le code promo %{promo_code} ?",
          "value": "Valeur : %{value}"
        },
        "show": {
          "title": "Code promo %{promo_code}"
        }
      },
      "search": {
        "index": {
          "search_results": "Résultats de recherche pour %{query}",
          "title": "Résultats de recherche pour %{query}"
        }
      },
      "stores": {
        "edit": {
          "title": "Modification de la boutique %{store}"
        },
        "form": {
          "billing_address": "Adresse facturation",
          "dns_setup_documentation_html": "Créez un enregistrement <code>CNAME</code> pointant vers <code>%{target}<c/ode> dans la zone DNS de votre domaine.",
          "information": "Informations générales",
          "payment_and_billing": "Paiement et facturation",
          "shipping_address": "Adresse de livraison",
          "store_enabled_help": "Les boutiques inactives restent cachées.",
          "urls": "URLs",
          "use_shipping_address": "Identique livraison"
        },
        "index": {
          "title": "Boutiques"
        },
        "new": {
          "title": "Nouvelle boutique"
        },
        "product": {
          "delete_confirmation": "Désactiver le produit %{product} de la boutique ?",
          "deleting": "Désactivation en cours...",
          "product_since": "Depuis le %{date}",
          "store_product": "Spécifique à %{store}",
          "variants": {
            "one": "1 variante",
            "other": "%{count} variantes",
            "zero": "Aucune variante"
          }
        },
        "show": {
          "add_admin": "Ajouter",
          "add_product": "Ajouter",
          "no_matching_products": "Aucun produit ne correspond à votre recherche.",
          "products": "Produits",
          "search_products": "Rechercher un produit par nom ou référence",
          "select_a_new_admin": "Sélectionnez un nouvel administrateur",
          "select_a_new_product": "Sélectionnez un nouveau produit",
          "sort": "Trier",
          "store_admins": "Administrateurs",
          "store_products": "Produits actifs",
          "title": "Boutique %{store}"
        },
        "sort_products": {
          "title": "Trier les produits"
        },
        "user": {
          "admin_since": "Depuis le %{date}",
          "delete_confirmation": "Supprimer l'administrateur %{user} de la boutique ?"
        }
      },
      "users": {
        "edit": {
          "title": "Modification de l'utilisateur %{user}"
        },
        "form": {
          "authentication": "Authentification",
          "information": "Informations générales"
        },
        "index": {
          "title": "Utilisateurs"
        },
        "new": {
          "title": "Nouvel utilisateur"
        },
        "show": {
          "title": "Utilisateur %{user}"
        },
        "user": {
          "created_at": "Créé(e) le : %{date}",
          "delete_confirmation": "Supprimer l'utilisateur %{user} ?",
          "email": "Email : %{email}",
          "user_type": "Type : %{type}"
        }
      }
    },
    "back": "Retour",
    "cancel": "Annuler",
    "cancelled_by_admin": "Annulée par l'administrateur",
    "cart_items": {
      "cart_item": {
        "color": "Couleur",
        "delete_confirmation": "Supprimer %{product_variant} de votre panier?",
        "incoming_stock_date": "Nous recevrons %{stock} articles le %{date}.",
        "no_incoming_stock_date": "Nous n'avons pas de date de réapprovisionnement pour le moment.",
        "no_stock_warning": "Nous n'avons plus cet article en stock.",
        "quantity": "Quantité",
        "size": "Taille",
        "stock_warning": "Nous n'avons que %{current_stock} articles en stock."
      }
    },
    "carts": {
      "show": {
        "delivery": "Livraison",
        "discount": "Remise",
        "my_cart": "Mon panier",
        "next": "Suivant",
        "ok": "OK",
        "processing": "Traitement...",
        "title": "Panier",
        "to_be_defined": "à définir",
        "total": "Total",
        "total_items": {
          "one": "1 article",
          "other": "%{count} articles",
          "zero": "Aucun article"
        }
      }
    },
    "controllers": {
      "addresses": {
        "update": {
          "notice": "Adresse mise à jour."
        }
      },
      "admin": {
        "orders": {
          "cancel": {
            "notice": "Commande annulée."
          },
          "ship": {
            "notice": "Commande marquée comme disponible pour le retrait."
          }
        },
        "parcels": {
          "create": {
            "notice": "Colis créé."
          },
          "update": {
            "notice": "Colis mis à jour."
          }
        },
        "product_categories": {
          "create": {
            "alert": "Echec de création de la catégorie.",
            "notice": "Catégorie créée."
          },
          "destroy": {
            "notice": "Catégorie supprimée."
          },
          "update": {
            "alert": "Echec de mise à jourt de la catégorie.",
            "notice": "Catégorie mise à jour."
          }
        },
        "product_colors": {
          "create": {
            "alert": "Echec de création de la couleur.",
            "notice": "Couleur créée."
          },
          "destroy": {
            "notice": "Couleur supprimée."
          },
          "update": {
            "alert": "Echec de mise à jourt de la couleur.",
            "notice": "Couleur mise à jour."
          }
        },
        "product_variants": {
          "create": {
            "alert": "Echec de création de la variante.",
            "notice": "Variante créée."
          },
          "destroy": {
            "notice": "Variante supprimée."
          },
          "destroy_image": {
            "notice": "Image supprimée."
          },
          "front": {
            "notice": "Variante mise en avant."
          },
          "update": {
            "alert": "Echec de mise à jour de la variante.",
            "notice": "Variante mise à jour."
          }
        },
        "products": {
          "create": {
            "alert": "Echec de création du produit.",
            "notice": "Produit créé."
          },
          "destroy": {
            "notice": "Produit supprimé."
          },
          "destroy_image": {
            "notice": "Image supprimée."
          },
          "disable_color": {
            "notice": "Toutes les variantes de couleur %{color} ont été désactivées pour cette boutique."
          },
          "duplicate": {
            "notice": "Produit dupliqué."
          },
          "enable_color": {
            "notice": "Toutes les variantes de couleur %{color} ont été activées pour cette boutique."
          },
          "update": {
            "alert": "Echec de mise à jour du produit.",
            "notice": "Produit mis à jour."
          }
        },
        "promo_codes": {
          "create": {
            "alert": "Echec de création du code promo.",
            "notice": "Code promo créé."
          },
          "destroy": {
            "notice": "Code promo supprimé."
          },
          "update": {
            "notice": "Code promo mis à jour."
          }
        },
        "stores": {
          "add_admin": {
            "notice": "Administrateur boutique ajouté."
          },
          "add_product": {
            "notice": "Produit en cours d'ajouté sur la boutique."
          },
          "create": {
            "alert": "Echec de création de la boutique.",
            "notice": "Boutique créée."
          },
          "current": {
            "notice": "Boutique sélectionnée."
          },
          "destroy": {
            "notice": "Boutique supprimée."
          },
          "remove_admin": {
            "notice": "Administrateur boutique supprimé. 👋"
          },
          "remove_product": {
            "notice": "Produit en cours de désactivation sur la boutique."
          },
          "update": {
            "alert": "Echec de mise à jour de la boutique.",
            "notice": "Boutique mise à jour."
          }
        },
        "users": {
          "create": {
            "alert": "Echec de création de l'utilisateur.",
            "notice": "Utilisateur créé."
          },
          "destroy": {
            "notice": "Utilisateur supprimé."
          },
          "update": {
            "alert": "Echec de mise à jourt de l'utilisateur.",
            "notice": "Utilisateur mis à jour."
          }
        }
      },
      "application": {
        "user_not_authorized": "Vous n'êtes pas autorisé à effectuer cette action."
      },
      "cart_items": {
        "create": {
          "notice": "%{product} ajouté au panier."
        },
        "destroy": {
          "notice": "%{product} supprimé."
        },
        "update": {
          "notice": "%{product} mis à jour."
        }
      },
      "carts": {
        "checkout": {
          "notice": "Commande créée."
        },
        "update": {
          "notice": "Panier mis à jour."
        }
      },
      "order_items": {
        "create": {
          "notice": "%{product} ajouté au panier."
        }
      },
      "orders": {
        "checkout": {
          "notice": "Commande créée, choisissez un mode de livraison."
        },
        "payment": {
          "alert": "Veuillez remplir vos informations de livraison et de facturation avant de passer à la paiement."
        },
        "update": {
          "notice": "Commande mise à jour."
        }
      },
      "store_admin": {
        "orders": {
          "cancel": {
            "notice": "Commande annulée."
          }
        },
        "product_variants": {
          "create": {
            "alert": "Echec de création de la variante.",
            "notice": "Variante créée."
          },
          "destroy": {
            "notice": "Variante supprimée."
          },
          "destroy_image": {
            "notice": "Image supprimée."
          },
          "front": {
            "notice": "Variante mise en avant."
          },
          "update": {
            "alert": "Echec de mise à jour de la variante.",
            "notice": "Variante mise à jour."
          }
        },
        "products": {
          "create": {
            "alert": "Echec de création du produit.",
            "notice": "Produit créé."
          },
          "destroy": {
            "notice": "Produit supprimé."
          },
          "destroy_image": {
            "notice": "Image supprimée."
          },
          "disable_color": {
            "notice": "Toutes les variantes de couleur %{color} ont été désactivées pour cette boutique."
          },
          "duplicate": {
            "notice": "Produit dupliqué."
          },
          "enable_color": {
            "notice": "Toutes les variantes de couleur %{color} ont été activées pour cette boutique."
          },
          "update": {
            "alert": "Echec de mise à jour du produit.",
            "notice": "Produit mis à jour."
          }
        },
        "promo_codes": {
          "create": {
            "alert": "Echec de création du code promo.",
            "notice": "Code promo créé."
          },
          "destroy": {
            "notice": "Code promo supprimé."
          },
          "update": {
            "notice": "Code promo mis à jour."
          }
        },
        "stores": {
          "add_product": {
            "notice": "Produit en cours d'ajouté sur la boutique."
          },
          "current": {
            "notice": "Boutique sélectionnée."
          },
          "remove_product": {
            "notice": "Produit en cours de désactivation sur la boutique."
          },
          "update": {
            "alert": "Echec de mise à jour de la boutique.",
            "notice": "Boutique mise à jour."
          }
        }
      }
    },
    "date": {
      "abbr_day_names": [
        "dim",
        "lun",
        "mar",
        "mer",
        "jeu",
        "ven",
        "sam"
      ],
      "abbr_month_names": [
        null,
        "jan.",
        "fév.",
        "mars",
        "avr.",
        "mai",
        "juin",
        "juil.",
        "août",
        "sept.",
        "oct.",
        "nov.",
        "déc."
      ],
      "day_names": [
        "dimanche",
        "lundi",
        "mardi",
        "mercredi",
        "jeudi",
        "vendredi",
        "samedi"
      ],
      "formats": {
        "default": "%d/%m/%Y",
        "long": "%-d %B %Y",
        "short": "%-d %b"
      },
      "month_names": [
        null,
        "janvier",
        "février",
        "mars",
        "avril",
        "mai",
        "juin",
        "juillet",
        "août",
        "septembre",
        "octobre",
        "novembre",
        "décembre"
      ],
      "order": [
        "day",
        "month",
        "year"
      ]
    },
    "datetime": {
      "distance_in_words": {
        "about_x_hours": {
          "one": "environ une heure",
          "other": "environ %{count} heures"
        },
        "about_x_months": {
          "one": "environ un mois",
          "other": "environ %{count} mois"
        },
        "about_x_years": {
          "one": "environ un an",
          "other": "environ %{count} ans"
        },
        "almost_x_years": {
          "one": "presque un an",
          "other": "presque %{count} ans"
        },
        "half_a_minute": "une demi‑minute",
        "less_than_x_minutes": {
          "one": "moins d'une minute",
          "other": "moins de %{count} minutes",
          "zero": "moins d'une minute"
        },
        "less_than_x_seconds": {
          "one": "moins d'une seconde",
          "other": "moins de %{count} secondes",
          "zero": "moins d'une seconde"
        },
        "over_x_years": {
          "one": "plus d'un an",
          "other": "plus de %{count} ans"
        },
        "x_days": {
          "one": "%{count} jour",
          "other": "%{count} jours"
        },
        "x_minutes": {
          "one": "%{count} minute",
          "other": "%{count} minutes"
        },
        "x_months": {
          "one": "%{count} mois",
          "other": "%{count} mois"
        },
        "x_seconds": {
          "one": "%{count} seconde",
          "other": "%{count} secondes"
        },
        "x_years": {
          "one": "%{count} an",
          "other": "%{count} ans"
        }
      },
      "prompts": {
        "day": "Jour",
        "hour": "Heure",
        "minute": "Minute",
        "month": "Mois",
        "second": "Seconde",
        "year": "Année"
      }
    },
    "delete": "Supprimer",
    "devise": {
      "confirmations": {
        "confirmed": "Votre compte a été validé.",
        "new": {
          "or_continue_with": "ou continuer avec",
          "resend_confirmation_info": "Envoyer les instructions",
          "title": "Compte non confirmé ?"
        },
        "send_instructions": "Vous allez recevoir les instructions nécessaires à la confirmation de votre compte dans quelques minutes.",
        "send_paranoid_instructions": "Si votre e-mail existe dans notre base de données, vous allez bientôt recevoir un e-mail contenant les instructions de confirmation de votre compte."
      },
      "failure": {
        "already_authenticated": "Vous êtes déjà connecté.",
        "inactive": "Votre compte n'est pas encore activé.",
        "invalid": "Email et/ou mot de passe incorrect(s).",
        "last_attempt": "Vous avez droit à une tentative avant que votre compte ne soit verrouillé.",
        "locked": "Votre compte est verrouillé.",
        "not_found_in_database": "Email et/ou mot de passe incorrect(s).",
        "timeout": "Votre session est expirée. Veuillez vous reconnecter pour continuer.",
        "unauthenticated": "Vous devez vous connecter ou vous inscrire pour continuer.",
        "unconfirmed": "Vous devez valider votre compte pour continuer."
      },
      "mailer": {
        "confirmation_instructions": {
          "action": "Confirmer mon email",
          "greeting": "Bienvenue %{recipient} !",
          "instruction": "Vous pouvez confirmer votre email grâce au lien ci-dessous :",
          "subject": "Instructions de confirmation"
        },
        "email_changed": {
          "greeting": "Bonjour %{recipient} !",
          "message": "Nous vous contactons pour vous informer que votre email a été changé en %{email}.",
          "message_unconfirmed": "Nous vous contactons pour vous informer que votre email est en train d'être changé par %{email}.",
          "subject": "Email modifié"
        },
        "password_change": {
          "greeting": "Bonjour %{recipient} !",
          "message": "Nous vous contactons pour vous notifier que votre mot de passe a été modifié.",
          "subject": "Mot de passe modifié"
        },
        "reset_password_instructions": {
          "action": "Changer mon mot de passe",
          "greeting": "Bonjour %{recipient} !",
          "instruction": "Quelqu'un a demandé un lien pour changer votre mot de passe, le voici :",
          "instruction_2": "Si vous n'avez pas émis cette demande, merci d'ignorer cet email.",
          "instruction_3": "Votre mot de passe ne changera pas tant que vous n'aurez pas cliqué sur ce lien et renseigné un nouveau mot de passe.",
          "subject": "Instructions pour changer le mot de passe"
        },
        "unlock_instructions": {
          "action": "Débloquer mon compte",
          "greeting": "Bonjour %{recipient} !",
          "instruction": "Suivez ce lien pour débloquer votre compte :",
          "message": "Votre compte a été bloqué suite à un nombre d'essais de connexions manquées trop important.",
          "subject": "Instructions pour déverrouiller le compte"
        }
      },
      "omniauth_callbacks": {
        "failure": "Nous n'avons pas pu vous authentifier via %{kind} : '%{reason}'.",
        "success": "Authentifié avec succès via %{kind}."
      },
      "passwords": {
        "edit": {
          "change_my_password": "Modifier mon mot de passe",
          "confirm_new_password": "Confirmer le nouveau mot de passe",
          "minimum_password_length": "Minimum de %{count} caractères",
          "new_password": "Nouveau mot de passe",
          "or_continue_with": "ou continuer avec",
          "title": "Modifier mon mot de passe"
        },
        "new": {
          "or_continue_with": "ou continuer avec",
          "send_password_reset_info": "Envoyer les instructions",
          "title": "Mot de passe oublié ?"
        },
        "no_token": "Vous ne pouvez accéder à cette page sans passer par un e-mail de réinitialisation de mot de passe. Si vous êtes passé par un e-mail de ce type, assurez-vous d'utiliser l'URL complète.",
        "send_instructions": "Vous allez recevoir les instructions de réinitialisation du mot de passe dans quelques instants.",
        "send_paranoid_instructions": "Si votre e-mail existe dans notre base de données, vous allez recevoir un lien de réinitialisation par e-mail.",
        "updated": "Votre mot de passe a été édité avec succès, vous êtes maintenant connecté.",
        "updated_not_active": "Votre mot de passe a été changé avec succès."
      },
      "registrations": {
        "destroyed": "Votre compte a été supprimé avec succès. Nous espérons vous revoir bientôt.",
        "new": {
          "already_a_member": "Déjà inscrit ?",
          "minimum_password_length": "Minimum de %{count} caractères",
          "or_continue_with": "ou continuer avec",
          "sign_up": "Inscription",
          "signin_to_your_account": "Connectez vous.",
          "title": "Inscription"
        },
        "signed_up": "Bienvenue, vous êtes connecté.",
        "signed_up_but_inactive": "Vous êtes bien enregistré. Vous ne pouvez cependant pas vous connecter car votre compte n'est pas encore activé.",
        "signed_up_but_locked": "Vous êtes bien enregistré. Vous ne pouvez cependant pas vous connecter car votre compte est verrouillé.",
        "signed_up_but_unconfirmed": "Un message contenant un lien de confirmation a été envoyé à votre adresse email. Ouvrez ce lien pour activer votre compte.",
        "update_needs_confirmation": "Votre compte a bien été mis à jour mais nous devons vérifier votre nouvelle adresse email. Merci de vérifier vos emails et de cliquer sur le lien de confirmation pour finaliser la validation de votre nouvelle adresse.",
        "updated": "Votre compte a été modifié avec succès.",
        "updated_but_not_signed_in": "Votre compte a été modifié avec succès. Merci de vous reconnecter."
      },
      "sessions": {
        "already_signed_out": "Déconnecté.",
        "new": {
          "not_a_member": "Pas encore inscrit ?",
          "or": "ou",
          "or_continue_with": "ou continuer avec",
          "remember_me": "Rester connecté",
          "sign_in": "Connexion",
          "signup": "Inscrivez-vous",
          "title": "Connexion"
        },
        "signed_in": "Ravi de vous revoir ! 👋",
        "signed_out": "A bientôt ! 👋"
      },
      "shared": {
        "links": {
          "didnt_receive_confirm_info": "Informations de confirmation non reçues ?",
          "didnt_receive_unlock_info": "Informations de déverrouillage non reçues ?",
          "forgot_password": "Mot de passe oublié ?",
          "signin": "Connexion",
          "signup": "Inscription"
        }
      },
      "unlocks": {
        "new": {
          "or_continue_with": "ou continuer avec",
          "resend_unlock_instructions": "Envoyer les instructions",
          "title": "Compte verrouillé ?"
        },
        "send_instructions": "Vous allez recevoir les instructions nécessaires au déverrouillage de votre compte dans quelques instants.",
        "send_paranoid_instructions": "Si votre compte existe, vous allez bientôt recevoir un email contenant les instructions pour le déverrouiller.",
        "unlocked": "Votre compte a été déverrouillé avec succès, vous êtes maintenant connecté."
      }
    },
    "display_period": "Période d'affichage",
    "edit": "Modifier",
    "errors": {
      "format": "%{attribute} %{message}",
      "messages": {
        "accepted": "doit être accepté(e)",
        "already_confirmed": "a déjà été validé(e), veuillez essayer de vous connecter",
        "aspect_ratio_is_not": "doit avoir un rapport hauteur / largeur de %{aspect_ratio}",
        "aspect_ratio_not_landscape": "doit être une image en format paysage",
        "aspect_ratio_not_portrait": "doit être une image en format portrait",
        "aspect_ratio_not_square": "doit être une image en format carrée",
        "blank": "doit être rempli(e)",
        "confirmation": "ne concorde pas avec %{attribute}",
        "confirmation_period_expired": "à confirmer dans les %{period}, merci de faire une nouvelle demande",
        "content_type_invalid": "a un type de contenu non valide",
        "dimension_height_equal_to": "la hauteur doit être égale à %{length} pixels",
        "dimension_height_greater_than_or_equal_to": "la hauteur doit être supérieure ou égale à %{length} pixels",
        "dimension_height_inclusion": "la hauteur n'est pas comprise entre %{min} et %{max} pixels",
        "dimension_height_less_than_or_equal_to": "la hauteur doit être inférieure ou égale à %{length} pixels",
        "dimension_max_inclusion": "doit être inférieur ou égal à %{width} x %{height} pixels",
        "dimension_min_inclusion": "doit être supérieur ou égal à %{width} x %{height} pixels",
        "dimension_width_equal_to": "la largeur doit être égale à %{length} pixels",
        "dimension_width_greater_than_or_equal_to": "la largeur doit être supérieure ou égale à %{length} pixels",
        "dimension_width_inclusion": "la largeur n'est pas comprise entre %{min} et %{max} pixels",
        "dimension_width_less_than_or_equal_to": "la largeur doit être inférieure ou égale à %{length} pixels",
        "empty": "doit être rempli(e)",
        "equal_to": "doit être égal à %{count}",
        "even": "doit être pair",
        "exclusion": "n'est pas disponible",
        "expired": "a expiré, merci d'en faire une nouvelle demande",
        "file_size_not_between": "la taille du fichier doit être comprise entre %{min_size} et %{max_size} (la taille actuelle est %{file_size})",
        "file_size_not_greater_than": "la taille du fichier doit être supérieure à %{min_size} (la taille actuelle est %{file_size})",
        "file_size_not_greater_than_or_equal_to": "la taille du fichier doit être supérieure ou égale à %{min_size} (la taille actuelle est %{file_size})",
        "file_size_not_less_than": "la taille du fichier doit être inférieure à %{max_size} (la taille actuelle est %{file_size})",
        "file_size_not_less_than_or_equal_to": "la taille du fichier doit être inférieure ou égale à %{max_size} (la taille actuelle est %{file_size})",
        "greater_than": "doit être supérieur à %{count}",
        "greater_than_or_equal_to": "doit être supérieur ou égal à %{count}",
        "image_metadata_missing": "n'est pas une image valide",
        "image_not_processable": "n'est pas une image valide",
        "in": "doit être dans l'intervalle %{count}",
        "inclusion": "n'est pas inclus(e) dans la liste",
        "invalid": "n'est pas valide",
        "less_than": "doit être inférieur à %{count}",
        "less_than_or_equal_to": "doit être inférieur ou égal à %{count}",
        "limit_out_of_range": "le nombre total est hors limites",
        "model_invalid": "Validation échouée : %{errors}",
        "not_a_number": "n'est pas un nombre",
        "not_an_integer": "doit être un nombre entier",
        "not_found": "n'a pas été trouvé(e)",
        "not_locked": "n'était pas verrouillé(e)",
        "not_saved": {
          "one": "1 erreur a empêché ce(tte) %{resource} d'être sauvegardé(e) :",
          "other": "%{count} erreurs ont empêché ce(tte) %{resource} d'être sauvegardé(e) :"
        },
        "odd": "doit être impair",
        "other_than": "doit être différent de %{count}",
        "present": "doit être vide",
        "required": "doit exister",
        "spoofed_content_type": "a un type de contenu qui n'est pas celui déclaré via son contenu, son nom de fichier et son extension",
        "taken": "est déjà utilisé(e)",
        "too_long": {
          "one": "est trop long (pas plus d'un caractère)",
          "other": "est trop long (pas plus de %{count} caractères)"
        },
        "too_short": {
          "one": "est trop court (au moins un caractère)",
          "other": "est trop court (au moins %{count} caractères)"
        },
        "total_file_size_not_between": "la taille totale des fichiers doit être comprise entre %{min_size} et %{max_size} (la taille actuelle est %{total_file_size})",
        "total_file_size_not_greater_than": "la taille totale des fichiers doit être supérieure à %{min_size} (la taille actuelle est %{total_file_size})",
        "total_file_size_not_greater_than_or_equal_to": "la taille totale des fichiers doit être supérieure ou égale à %{min_size} (la taille actuelle est %{total_file_size})",
        "total_file_size_not_less_than": "la taille totale des fichiers doit être inférieure à %{max_size} (la taille actuelle est %{total_file_size})",
        "total_file_size_not_less_than_or_equal_to": "la taille totale des fichiers doit être inférieure ou égale à %{max_size} (la taille actuelle est %{total_file_size})",
        "wrong_length": {
          "one": "ne fait pas la bonne longueur (doit comporter un seul caractère)",
          "other": "ne fait pas la bonne longueur (doit comporter %{count} caractères)"
        }
      },
      "template": {
        "body": "Veuillez vérifier les champs suivants : ",
        "header": {
          "one": "Impossible d'enregistrer ce(tte) %{model} : %{count} erreur",
          "other": "Impossible d'enregistrer ce(tte) %{model} : %{count} erreurs"
        }
      }
    },
    "export": "Exporter",
    "favorites": {
      "favorite": {
        "add_to_cart": "Ajouter au panier"
      },
      "index": {
        "title": "Mes favoris"
      }
    },
    "filter": "Filtrer :",
    "filters": {
      "periods": {
        "all_time": "Depuis toujours",
        "this_month": "Ce mois",
        "this_week": "Cette semaine",
        "this_year": "Cette année",
        "today": "Aujourd'hui"
      }
    },
    "helpers": {
      "select": {
        "prompt": "Veuillez sélectionner"
      },
      "submit": {
        "address": {
          "create": "Créer l'%{model}",
          "update": "Modifier l'%{model}"
        },
        "create": "Créer un(e) %{model}",
        "product": {
          "create": "Créer le %{model}",
          "update": "Modifier le %{model}"
        },
        "product_category": {
          "create": "Créer la %{model}",
          "update": "Modifier la %{model}"
        },
        "product_color": {
          "create": "Créer la %{model}",
          "update": "Modifier la %{model}"
        },
        "product_variant": {
          "create": "Créer la %{model}",
          "update": "Modifier la %{model}"
        },
        "promo_code": null,
        "store": {
          "create": "Créer la %{model}",
          "update": "Modifier la %{model}"
        },
        "submit": "Enregistrer ce(tte) %{model}",
        "update": "Modifier ce(tte) %{model}",
        "user": {
          "create": "Créer l'%{model}",
          "update": "Modifier l'%{model}"
        }
      }
    },
    "home": "Accueil",
    "i18n": {
      "plural": {
        "keys": [
          "one",
          "other"
        ]
      },
      "transliterate": {
        "rule": {
          "À": "A",
          "Â": "A",
          "Æ": "Ae",
          "Ç": "C",
          "È": "E",
          "É": "E",
          "Ê": "E",
          "Ë": "E",
          "Î": "I",
          "Ï": "I",
          "Ô": "O",
          "Ù": "U",
          "Û": "U",
          "Ü": "U",
          "à": "a",
          "â": "a",
          "æ": "ae",
          "ç": "c",
          "è": "e",
          "é": "e",
          "ê": "e",
          "ë": "e",
          "î": "i",
          "ï": "i",
          "ô": "o",
          "ù": "u",
          "û": "u",
          "ü": "u",
          "ÿ": "y",
          "Œ": "Oe",
          "œ": "oe",
          "Ÿ": "Y"
        }
      }
    },
    "layouts": {
      "admin": {
        "all_stores": "Toutes les boutiques",
        "commissions": "Commissions",
        "configuration": "Configuration",
        "dashboard": "Tableau de bord",
        "jobs": "Tâches",
        "monitoring": "Surveillance",
        "orders": "Commandes",
        "product_categories": "Catégories produits",
        "product_colors": "Couleurs produits",
        "products": "Produits",
        "promo_codes": "Codes promo",
        "statistics": "Statistiques",
        "stores": "Boutiques",
        "users": "Utilisateurs",
        "your_profile": "Votre profil"
      },
      "application": {
        "legal": "Mentions légales",
        "privacy": "Politique de confidentialité",
        "terms": "Conditions générales de vente"
      },
      "store_admin": {
        "all_my_stores": "Toutes mes boutiques",
        "configuration": "Configuration",
        "dashboard": "Tableau de bord",
        "orders": "Commandes",
        "products": "Produits",
        "promo_codes": "Codes promo",
        "statistics": "Statistiques",
        "stores": "Boutiques",
        "your_profile": "Votre profil"
      }
    },
    "missing": "Manquant(e)",
    "no_data": "Aucune donnée à afficher 🥲",
    "number": {
      "currency": {
        "format": {
          "delimiter": " ",
          "format": "%n %u",
          "precision": 2,
          "separator": ",",
          "significant": false,
          "strip_insignificant_zeros": false,
          "unit": "€"
        }
      },
      "format": {
        "delimiter": " ",
        "precision": 3,
        "round_mode": "default",
        "separator": ",",
        "significant": false,
        "strip_insignificant_zeros": false
      },
      "human": {
        "decimal_units": {
          "format": "%n %u",
          "units": {
            "billion": "milliard",
            "million": "million",
            "quadrillion": "million de milliards",
            "thousand": "millier",
            "trillion": "billion",
            "unit": ""
          }
        },
        "format": {
          "delimiter": "",
          "precision": 3,
          "significant": true,
          "strip_insignificant_zeros": true
        },
        "storage_units": {
          "format": "%n %u",
          "units": {
            "byte": {
              "one": "octet",
              "other": "octets"
            },
            "eb": "Eo",
            "gb": "Go",
            "kb": "ko",
            "mb": "Mo",
            "pb": "Po",
            "tb": "To"
          }
        }
      },
      "nth": {},
      "percentage": {
        "format": {
          "delimiter": "",
          "format": "%n%"
        }
      },
      "precision": {
        "format": {
          "delimiter": ""
        }
      }
    },
    "open": "Ouvrir",
    "order_items": {
      "order_item": {
        "color": "Couleur",
        "quantity": "Quantité",
        "size": "Taille"
      }
    },
    "orders": {
      "delivery": {
        "account": "Compte",
        "billing_address": "Adresse de facturation",
        "billing_addresses": "Utiliser une adresse de facturation",
        "free": "Gratuit",
        "maximum_incoming_stock_date": "Seconde expédition possible après le %{date}.",
        "multiple_deliveries_help": "Les articles en stock seront expédiés immédiatement et le reste plus tard. Si vous optez pour la livraison, vous paierez deux fois les frais de port.",
        "my_order": "Ma commande",
        "next": "Suivant",
        "no_incoming_stock_date": "Nous ne savons pas encore quand nous recevrons les articles manquants.",
        "processing": "Traitement...",
        "shipping_address": "Adresse de livraison",
        "shipping_addresses": "Utiliser une adresse de livraison",
        "stock_warning": "Nous n'avons pas l'intégralité de vos articles en stock. Vous pouvez opter pour une expédition des articles en stock suivi d'une seconde livraison avec le reste des articles lors que nous les aurons.",
        "title": "Livraison",
        "use_shipping_address": "Identique livraison"
      },
      "failure": {
        "error_occured": "Une erreur est survenue pendant la validation de votre commande dans la boutique %{store}.",
        "failure": "Commande non validée. 👎",
        "my_order": "Ma commande",
        "retry": "Réessayer",
        "title": "Commande annulée"
      },
      "index": {
        "my_orders": "Mes commandes",
        "title": "Commandes"
      },
      "invoice": {
        "delivery": "Livraison",
        "discount": "Remise",
        "item": {
          "name": "Article",
          "quantity": "Quantité",
          "total": "Total",
          "unit_price": "Prix unitaire"
        },
        "order_reference": "Référence de la commande",
        "paid_at": "Date de paiement",
        "subtotal": "Sous-total",
        "taxless_subtotal": "Sous-total HT",
        "title": "Facture %{reference}",
        "total": "Total",
        "vat": "TVA"
      },
      "order": {
        "delivery": "Expédition : %{type}",
        "paid_at": "Payée le : %{date}",
        "total": "Total : %{amount}"
      },
      "order_information": {
        "delivery": "Livraison",
        "discount": "Remise",
        "free": "Gratuite",
        "to_be_defined": "à définir",
        "total": "Total",
        "total_items": {
          "one": "1 article",
          "other": "%{count} articles",
          "zero": "Aucun article"
        }
      },
      "payment": {
        "next": "Suivant",
        "title": "Paiement"
      },
      "show": {
        "invoice": "Facture %{reference}",
        "my_orders": "Mes commandes",
        "state": "État de la commande :",
        "title": "Commande %{reference}"
      },
      "success": {
        "my_order": "Ma commande",
        "next": "Suivant",
        "success": "Commande validée ! 👍",
        "thank_you_for_your_order": "Merci pour votre commande dans la boutique %{store}.",
        "title": "Commande validée"
      }
    },
    "products": {
      "product": {
        "add_to_cart": "Ajouter"
      },
      "search": {
        "accessories": "Accessoires",
        "all_products": "Tous nos produits",
        "clothing": "Vêtements",
        "filter_color": "Couleurs",
        "filter_mode": "Mode",
        "filter_products": "Catégorie de produits",
        "filter_size": "Tailles",
        "pagy_product": {
          "one": "produit",
          "other": "produits",
          "zero": "produit"
        },
        "search_products": "Rechercher des produits",
        "title": "Résultats de recherche"
      },
      "show": {
        "add_to_cart": "Ajouter au panier",
        "back_customization": "Dos (%{price})",
        "chest_customization": "Poitrine (%{price})",
        "colors": "Couleurs",
        "customize": "Personnaliser",
        "customize_your_product": "Personnalisez votre produit",
        "description": "Description",
        "heart_customization": "Coeur (%{price})",
        "incoming_stock_date": "Réapprovisionnement à partir du %{date}.",
        "left_sleeve_customization": "Manche gauche (%{price})",
        "out_of_stock": "Nous n'avons plus cet article en stock.",
        "quantity": "Quantité",
        "right_sleeve_customization": "Manche droite (%{price})",
        "similar_products": "Produits similaires",
        "sizes": "Tailles",
        "sizes_guide": "Guide des tailles",
        "title": "Produit %{product}"
      }
    },
    "profiles": {
      "edit": {
        "account": "Compte",
        "leave_blank_if_you_dont_want_to_change_it": "(laissez vide si vous ne souhaitez pas le changer)",
        "minimum_password_length": "Minimum de %{count} caractères",
        "my_information": "Mon compte",
        "save": "Sauvegarder",
        "title": "Mon compte",
        "waiting_confirmation": "En attente de confirmation pour %{email}"
      }
    },
    "search": {
      "result": {
        "created_at": "Créé(e) il y a %{time}",
        "updated_at": "Mis(e) à jour il y a %{time}"
      }
    },
    "search_placeholder": "Rechercher...",
    "shared": {
      "account_navigation": {
        "addresses": "Adresses",
        "orders": "Commandes",
        "profile": "Profile"
      },
      "form_errors": {
        "save_error": "Erreur lors de la sauvegarde"
      },
      "funnel_steps": {
        "cart": "Panier",
        "delivery": "Livraison",
        "summary": "Récapitulatif",
        "summary_short": "Récap"
      }
    },
    "show": "Afficher",
    "store_admin": {
      "dashboard": {
        "index": {
          "last_month_orders": "Commandes du mois dernier par jour",
          "title": "Statistiques"
        }
      },
      "orders": {
        "index": {
          "title": "Commandes"
        },
        "order": {
          "delivery": "Expédition : %{type}",
          "paid_at": "Payée le : %{date}",
          "total": "Total : %{amount}"
        },
        "show": {
          "actions": "Actions disponibles",
          "billing_address": "Adresse de facturation",
          "cancel": "Annuler",
          "cancel_confirmation": "Annuler la commande %{order} ?",
          "customer": "Client",
          "delivery_to_store": "📦 Livraison à la boutique %{store}",
          "invoices": "Factures",
          "items": "Articles",
          "multiple_deliveries": "Expédition multiple",
          "no_customer": "Aucun client",
          "order": "Commande",
          "parcels": "Colis",
          "shipping_address": "Adresse de livraison",
          "summary": "Résumé",
          "title": "Commande %{order}"
        }
      },
      "product_variants": {
        "edit": {
          "title": "Modification de la variante %{product_variant}"
        },
        "form": {
          "base_variant": "Il s'agit de la variante de base importée depuis le fournisseur et n'étant pas liée à une boutique.",
          "batch_create": "Création en lot",
          "batch_update": "Mise à jour en lot",
          "create_other_product_variants_help": "Si activé, le variant sera dupliqué pour toutes les tailles du produit.",
          "images": "Images",
          "information": "Informations générales",
          "no_images": "Aucune image",
          "no_primary_image": "Aucune image principale",
          "pricing": "Tarification",
          "product_variant_enabled_help": "Les variantes de produits inactives ne sont pas achetables et restent cachés.",
          "store": "Boutique",
          "store_variant": "Il s'agit d'une variante liée à la boutique %{store}.",
          "supplier": "Informations fournisseur (%{supplier})",
          "update_other_product_variants_help": "Si activé, le nom et le prix de cette variante seront appliqués aux autres variantes de ce produit. Les images des variantes de taille différente, mais avec les mêmes couleurs, seront également mises à jour."
        },
        "image": {
          "delete": "Supprimer",
          "delete_confirmation": "Supprimer cette image ?"
        },
        "index": {
          "title": "Variantes de %{product}"
        },
        "new": {
          "title": "Nouvelle variante pour %{product}"
        },
        "product_variant": {
          "delete_confirmation": "Supprimer la variante %{product_variant} ?",
          "global": "Commun à toutes les boutiques",
          "price": "Prix : %{price}",
          "size": "Taille : %{size}",
          "specific_to_store": "Spécifique à %{store}"
        },
        "show": {
          "title": "Variante %{product_variant}"
        },
        "update": {
          "title": "Modification de la variante %{product_variant}"
        }
      },
      "products": {
        "edit": {
          "title": "Modification du produit %{product}"
        },
        "form": {
          "base_product": "Il s'agit du produit de base importé depuis le fournisseur et n'étant pas liée à une boutique.",
          "images": "Images",
          "information": "Informations générales",
          "no_images": "Aucune image",
          "no_sizes_guide": "Aucun guide de tailles disponible",
          "open_sizes_guide": "Voir le guide",
          "pricing": "Tarification",
          "product_enabled_help": "Les produits inactifs ne sont pas achetables et restent cachés.",
          "store": "Boutique",
          "store_product": "Il s'agit d'un produit lié à la boutique %{store}.",
          "supplier": "Fournisseur",
          "tags_placeholder": "Séparez vos tags par un espace"
        },
        "image": {
          "delete": "Supprimer",
          "delete_confirmation": "Supprimer cette image ?"
        },
        "index": {
          "title": "Mes produits"
        },
        "new": {
          "title": "Nouveau produit"
        },
        "product": {
          "created_at": "Créé le : %{date}",
          "delete_confirmation": "Supprimer le produit %{product} ?",
          "global": "Commun à toutes les boutiques",
          "reference": "Référence : %{reference}",
          "specific_to_store": "Spécifique à %{store}",
          "supplier": "Fournisseur : %{supplier}"
        },
        "show": {
          "add_product_variant": "Ajouter un variant",
          "disable_all": "Désactiver tout",
          "disable_color_confirmation": "Désactiver toutes les variantes de la couleur %{color} ?",
          "duplicate_for_store": "Dupliquer pour %{store}",
          "duplicate_for_store_confirmation": "Dupliquer le produit et tous ses variants pour la boutique %{store} ?",
          "enable_all": "Activer tout",
          "enable_color_confirmation": "Activer toutes les variantes de la couleur %{color} ?",
          "title": "Produit %{product}",
          "variants": "Variantes"
        },
        "update": {
          "title": "Modification du produit %{product}"
        }
      },
      "promo_codes": {
        "edit": {
          "title": "Modification du code promo %{promo_code}"
        },
        "form": {
          "information": "Informations générales",
          "promo_code_enabled_help": "Les codes promo inactifs ne sont pas utilisables sur les boutiques."
        },
        "index": {
          "title": "Codes promo"
        },
        "new": {
          "title": "Nouveau code promo"
        },
        "promo_code": {
          "code_type": "Type : %{type}",
          "created_at": "Créé le : %{date}",
          "delete_confirmation": "Supprimer le code promo %{promo_code} ?",
          "value": "Valeur : %{value}"
        },
        "show": {
          "title": "Code promo %{promo_code}"
        }
      },
      "search": {
        "index": {
          "search_results": "Résultats de recherche pour %{query}",
          "title": "Résultats de recherche pour %{query}"
        }
      },
      "stores": {
        "edit": {
          "title": "Modification de la boutique %{store}"
        },
        "form": {
          "billing_address": "Adresse facturation",
          "dns_setup_documentation_html": "Créez un enregistrement <code>CNAME</code> pointant vers <code>%{target}<c/ode> dans la zone DNS de votre domaine.",
          "information": "Informations générales",
          "payment_and_billing": "Paiement et facturation",
          "shipping_address": "Adresse de livraison",
          "store_enabled_help": "Les boutiques inactives restent cachées.",
          "urls": "URLs",
          "use_shipping_address": "Identique livraison"
        },
        "index": {
          "title": "Mes boutiques"
        },
        "product": {
          "delete_confirmation": "Désactiver le produit %{product} de la boutique ?",
          "deleting": "Désactivation en cours...",
          "product_since": "Depuis le %{date}",
          "store_product": "Spécifique à %{store}",
          "variants": {
            "one": "1 variante",
            "other": "%{count} variantes",
            "zero": "Aucune variante"
          }
        },
        "show": {
          "add_admin": "Ajouter",
          "add_product": "Ajouter",
          "no_matching_products": "Aucun produit ne correspond à votre recherche.",
          "products": "Produits",
          "search_products": "Rechercher un produit par nom ou référence",
          "select_a_new_admin": "Sélectionnez un nouvel administrateur",
          "select_a_new_product": "Sélectionnez un nouveau produit",
          "sort": "Trier",
          "store_admins": "Administrateurs",
          "store_products": "Produits actifs",
          "title": "Boutique %{store}"
        },
        "sort_products": {
          "title": "Trier les produits"
        },
        "user": {
          "admin_since": "Depuis le %{date}"
        }
      }
    },
    "stores": {
      "show": {
        "accessories": "Accessoires",
        "all_products": "Tous nos produits",
        "clothing": "Vêtements",
        "filter_color": "Couleurs",
        "filter_mode": "Mode",
        "filter_products": "Catégorie de produits",
        "filter_size": "Tailles",
        "pagy_product": {
          "one": "produit",
          "other": "produits",
          "zero": "produit"
        },
        "search_products": "Rechercher des produits",
        "title": "Tous nos produits"
      }
    },
    "support": {
      "array": {
        "last_word_connector": " et ",
        "two_words_connector": " et ",
        "words_connector": ", "
      }
    },
    "time": {
      "am": "am",
      "formats": {
        "default": "%d %B %Y %Hh %Mmin %Ss",
        "long": "%A %d %B %Y %Hh%M",
        "short": "%d %b %Hh%M",
        "time": "%H:%M"
      },
      "pm": "pm"
    }
  }
});
i18n.locale = window.Sofidial.locale;
export default i18n;
